import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField, InfoBox } from "@cobira/ui-library";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useApi";

const SelectUsagePackageFormSchema = z.object({
    usagePackageType: z.object(
        {
            id: z.string(),
            customName: z.string().nullable().optional(),
            description: z.string().nullable().optional(),
        },
        { required_error: "Usage Package is required" },
    ),
});

export type SelectUsagePackageFormType = z.infer<typeof SelectUsagePackageFormSchema>;

export interface SelectUsagePackageFormProps {
    includeDeleted: boolean;
    onSubmit: (form: SelectUsagePackageFormType) => void;
    onCancel?: () => void;
}

const SelectUsagePackageForm = ({ onSubmit, onCancel, includeDeleted }: SelectUsagePackageFormProps) => {
    const { usagePackageApi } = useApi();
    const { data: usagePackageTypes } = useQuery(["usage-packages", includeDeleted], () =>
        usagePackageApi.getAvailableUsagePackageTypes({ includeDeleted: includeDeleted }),
    );
    const {
        formState: { errors },
        handleSubmit,
        control,
        watch,
    } = useForm<SelectUsagePackageFormType>({
        resolver: zodResolver(SelectUsagePackageFormSchema),
    });
    const selectedUsagePackageType = watch("usagePackageType");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Usage Package"} error={errors?.usagePackageType}>
                    <ControlledAutoCompleteSingleSelectInput
                        input={{
                            items: usagePackageTypes || [],
                            displayMapping: value => value.customName,
                            autocompleteAbleMapping: value => value?.customName || "",
                            placeholder: "Choose Usage Package",
                        }}
                        control={{
                            name: "usagePackageType",
                            control: control,
                        }}
                    />
                </FormField>
                {selectedUsagePackageType && (
                    <InfoBox
                        title={selectedUsagePackageType?.customName}
                        description={selectedUsagePackageType?.description}
                        status={"info"}
                    />
                )}
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Confirm
                </Button>
                {oncancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default SelectUsagePackageForm;
