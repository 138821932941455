import { useApi } from "./useApi";
import { AsyncMetricsResolver } from "@cobira/ui-library";
import { mapMetricResponse } from "../utils/mappers";
import { Dimension, MetricGroup } from "../api";

export const useMetricsResolver = (): AsyncMetricsResolver => {
    const { metricsApi } = useApi();

    return ({ dataPoint, group, groupId, fromDateTime, toDateTime, dimensions }) =>
        metricsApi
            .getMetrics({
                group: group as MetricGroup,
                groupId: groupId,
                dataPoint: dataPoint,
                fromDateTime: fromDateTime,
                toDateTime: toDateTime,
                dimensions: dimensions as Dimension[],
            })
            .then(value => value.map(mapMetricResponse));
};
