import React from "react";
import { ActionItemState } from "../../api";
import { IndicatorColors, IndicatorLabel } from "@cobira/ui-library";

interface ActionItemStateIndicatorProps {
    state?: ActionItemState | undefined;
    withLabel?: boolean;
}

const ActionItemStateIndicator = ({ state, withLabel }: ActionItemStateIndicatorProps) => {
    let color: IndicatorColors = "gray";
    let label = "Unknown";
    if (state) {
        switch (state) {
            case "FAILED":
                color = "red";
                label = "Failed";
                break;
            case "PENDING":
                color = "gray";
                label = "Pending";
                break;
            case "SKIPPED":
                color = "yellow";
                label = "Skipped";
                break;
            case "SUCCESS":
                color = "green";
                label = "Success";
                break;
        }
    }

    return <IndicatorLabel text={withLabel ? label : ""} color={color} />;
};

export default ActionItemStateIndicator;
