import { ButtonGroup, IconButton, useEditableControls } from "@chakra-ui/react";
import React from "react";
import { faCheck, faX } from "@fortawesome/pro-regular-svg-icons";
import { EditIcon, FaIcon } from "@cobira/ui-library";

export const SimCardFormButtonControls = () => {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls();
    if (isEditing) {
        return (
            <ButtonGroup size={"xs"} variant={"icon"}>
                <IconButton
                    icon={<FaIcon icon={faCheck} />}
                    {...getSubmitButtonProps()}
                    type={"submit"}
                    aria-label={"submit"}
                />
                <IconButton icon={<FaIcon icon={faX} />} {...getCancelButtonProps()} aria-label={"cancel"} />
            </ButtonGroup>
        );
    }

    return (
        <IconButton size={"xs"} variant={"icon"} icon={<EditIcon />} {...getEditButtonProps()} aria-label={"edit"} />
    );
};
