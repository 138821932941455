/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        BillingGroup,
        BillingGroupDataUsage,
        BillingGroupSmsUsage,
    } from '../models';
        import {
            BillingGroupFromJSON,
            BillingGroupToJSON,
            BillingGroupDataUsageFromJSON,
            BillingGroupDataUsageToJSON,
            BillingGroupSmsUsageFromJSON,
            BillingGroupSmsUsageToJSON,
        } from '../models';

            export interface BillingGroupApiGetBillingGroupRequest {
                billingGroupId: string;
            }

            export interface BillingGroupApiGetBillingGroupDataUsageRequest {
                billingGroupId: string;
                fromYear?: number;
                fromMonth?: GetBillingGroupDataUsageFromMonthEnum;
                toYear?: number;
                toMonth?: GetBillingGroupDataUsageToMonthEnum;
            }

            export interface BillingGroupApiGetBillingGroupSmsUsageRequest {
                billingGroupId: string;
                fromYear?: number;
                fromMonth?: GetBillingGroupSmsUsageFromMonthEnum;
                toYear?: number;
                toMonth?: GetBillingGroupSmsUsageToMonthEnum;
            }

        /**
        * 
        */
            export class BillingGroupApi extends runtime.BaseAPI {

            /**
                * Get a single billing group using the billing group id.
                * Get billing groups
            */
            async getBillingGroupRaw(requestParameters: BillingGroupApiGetBillingGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingGroup>> {
                    if (requestParameters.billingGroupId === null || requestParameters.billingGroupId === undefined) {
                    throw new runtime.RequiredError('billingGroupId','Required parameter requestParameters.billingGroupId was null or undefined when calling getBillingGroup.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/billinggroups/{billingGroupId}`.replace(`{${"billingGroupId"}}`, encodeURIComponent(String(requestParameters.billingGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => BillingGroupFromJSON(jsonValue));
            }

            /**
                * Get a single billing group using the billing group id.
                * Get billing groups
            */
                async getBillingGroup(requestParameters: BillingGroupApiGetBillingGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingGroup> {
                    const response = await this.getBillingGroupRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get data usage across an entire billing group. This endpoint is grouped by year, month, IMSI, and network, for each sim card assigned to the billing group.  If you do not specify any `fromYear`/`fromMonth`, the beginning of recorded time will be used.  If you do not specify any bounds, the entire available data set will be available.
                * Get billing group data usage
            */
            async getBillingGroupDataUsageRaw(requestParameters: BillingGroupApiGetBillingGroupDataUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingGroupDataUsage>> {
                    if (requestParameters.billingGroupId === null || requestParameters.billingGroupId === undefined) {
                    throw new runtime.RequiredError('billingGroupId','Required parameter requestParameters.billingGroupId was null or undefined when calling getBillingGroupDataUsage.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.fromYear !== undefined) {
                            queryParameters['fromYear'] = requestParameters.fromYear;
                    }

                    if (requestParameters.fromMonth !== undefined) {
                            queryParameters['fromMonth'] = requestParameters.fromMonth;
                    }

                    if (requestParameters.toYear !== undefined) {
                            queryParameters['toYear'] = requestParameters.toYear;
                    }

                    if (requestParameters.toMonth !== undefined) {
                            queryParameters['toMonth'] = requestParameters.toMonth;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/billinggroups/{billingGroupId}/usage/data`.replace(`{${"billingGroupId"}}`, encodeURIComponent(String(requestParameters.billingGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => BillingGroupDataUsageFromJSON(jsonValue));
            }

            /**
                * Get data usage across an entire billing group. This endpoint is grouped by year, month, IMSI, and network, for each sim card assigned to the billing group.  If you do not specify any `fromYear`/`fromMonth`, the beginning of recorded time will be used.  If you do not specify any bounds, the entire available data set will be available.
                * Get billing group data usage
            */
                async getBillingGroupDataUsage(requestParameters: BillingGroupApiGetBillingGroupDataUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingGroupDataUsage> {
                    const response = await this.getBillingGroupDataUsageRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get SMS usage across an entire billing group. This endpoint is grouped by year, month, IMSI, and network, for each sim card assigned to the billing group.  If you do not specify any `fromYear`/`fromMonth`, the beginning of recorded time will be used.  If you do not specify any bounds, the entire available data set will be available.
                * Get billing group SMS usage
            */
            async getBillingGroupSmsUsageRaw(requestParameters: BillingGroupApiGetBillingGroupSmsUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingGroupSmsUsage>> {
                    if (requestParameters.billingGroupId === null || requestParameters.billingGroupId === undefined) {
                    throw new runtime.RequiredError('billingGroupId','Required parameter requestParameters.billingGroupId was null or undefined when calling getBillingGroupSmsUsage.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.fromYear !== undefined) {
                            queryParameters['fromYear'] = requestParameters.fromYear;
                    }

                    if (requestParameters.fromMonth !== undefined) {
                            queryParameters['fromMonth'] = requestParameters.fromMonth;
                    }

                    if (requestParameters.toYear !== undefined) {
                            queryParameters['toYear'] = requestParameters.toYear;
                    }

                    if (requestParameters.toMonth !== undefined) {
                            queryParameters['toMonth'] = requestParameters.toMonth;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/billinggroups/{billingGroupId}/usage/sms`.replace(`{${"billingGroupId"}}`, encodeURIComponent(String(requestParameters.billingGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => BillingGroupSmsUsageFromJSON(jsonValue));
            }

            /**
                * Get SMS usage across an entire billing group. This endpoint is grouped by year, month, IMSI, and network, for each sim card assigned to the billing group.  If you do not specify any `fromYear`/`fromMonth`, the beginning of recorded time will be used.  If you do not specify any bounds, the entire available data set will be available.
                * Get billing group SMS usage
            */
                async getBillingGroupSmsUsage(requestParameters: BillingGroupApiGetBillingGroupSmsUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingGroupSmsUsage> {
                    const response = await this.getBillingGroupSmsUsageRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get the billing groups that has been assigned to you. Billing groups are managed by your MVNO.
                * Get billing groups
            */
            async getBillingGroupsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BillingGroup>>> {
            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/billinggroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BillingGroupFromJSON));
            }

            /**
                * Get the billing groups that has been assigned to you. Billing groups are managed by your MVNO.
                * Get billing groups
            */
                async getBillingGroups(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BillingGroup>> {
                    const response = await this.getBillingGroupsRaw(initOverrides);
                    return await response.value();
                }

        }

                            /**
                            * @export
                            */
                            export const GetBillingGroupDataUsageFromMonthEnum = {
                                January: 'JANUARY',
                                February: 'FEBRUARY',
                                March: 'MARCH',
                                April: 'APRIL',
                                May: 'MAY',
                                June: 'JUNE',
                                July: 'JULY',
                                August: 'AUGUST',
                                September: 'SEPTEMBER',
                                October: 'OCTOBER',
                                November: 'NOVEMBER',
                                December: 'DECEMBER'
                            } as const;
                            export type GetBillingGroupDataUsageFromMonthEnum = typeof GetBillingGroupDataUsageFromMonthEnum[keyof typeof GetBillingGroupDataUsageFromMonthEnum];
                            /**
                            * @export
                            */
                            export const GetBillingGroupDataUsageToMonthEnum = {
                                January: 'JANUARY',
                                February: 'FEBRUARY',
                                March: 'MARCH',
                                April: 'APRIL',
                                May: 'MAY',
                                June: 'JUNE',
                                July: 'JULY',
                                August: 'AUGUST',
                                September: 'SEPTEMBER',
                                October: 'OCTOBER',
                                November: 'NOVEMBER',
                                December: 'DECEMBER'
                            } as const;
                            export type GetBillingGroupDataUsageToMonthEnum = typeof GetBillingGroupDataUsageToMonthEnum[keyof typeof GetBillingGroupDataUsageToMonthEnum];
                            /**
                            * @export
                            */
                            export const GetBillingGroupSmsUsageFromMonthEnum = {
                                January: 'JANUARY',
                                February: 'FEBRUARY',
                                March: 'MARCH',
                                April: 'APRIL',
                                May: 'MAY',
                                June: 'JUNE',
                                July: 'JULY',
                                August: 'AUGUST',
                                September: 'SEPTEMBER',
                                October: 'OCTOBER',
                                November: 'NOVEMBER',
                                December: 'DECEMBER'
                            } as const;
                            export type GetBillingGroupSmsUsageFromMonthEnum = typeof GetBillingGroupSmsUsageFromMonthEnum[keyof typeof GetBillingGroupSmsUsageFromMonthEnum];
                            /**
                            * @export
                            */
                            export const GetBillingGroupSmsUsageToMonthEnum = {
                                January: 'JANUARY',
                                February: 'FEBRUARY',
                                March: 'MARCH',
                                April: 'APRIL',
                                May: 'MAY',
                                June: 'JUNE',
                                July: 'JULY',
                                August: 'AUGUST',
                                September: 'SEPTEMBER',
                                October: 'OCTOBER',
                                November: 'NOVEMBER',
                                December: 'DECEMBER'
                            } as const;
                            export type GetBillingGroupSmsUsageToMonthEnum = typeof GetBillingGroupSmsUsageToMonthEnum[keyof typeof GetBillingGroupSmsUsageToMonthEnum];
