import { ActionProgressNotification } from "@cobira/ui-library";
import React, { ReactNode } from "react";
import { ActionApi } from "../../../api";

export interface SimCardActionInProgressNotificationProps {
    actionApi: ActionApi;
    actionId: string;
    title: ReactNode;
    message: (processedSimCount: number, totalSimCount: number) => ReactNode;
}

const SimCardActionInProgressNotification = ({
    actionApi,
    actionId,
    title,
    message,
}: SimCardActionInProgressNotificationProps) => {
    return (
        <ActionProgressNotification
            actionId={actionId}
            query={{ queryFn: () => actionApi.getAction({ actionId: actionId }) }}
            title={title}
            message={action => message(action.itemsProcessed || 0, action.totalItems || 0)}
        />
    );
};

export default SimCardActionInProgressNotification;
