/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Possible automatic activation types.
 * [DISABLED] Means that the usage package will only activate by manual request.
 * [FIRST_PDP_SESSION] Will automatically activate the usage package when a device with the configured SIM card spends its' first byte.
 * [FIRST_LOCATION_UPDATE] Will automatically activate the usage package when a device registers with a network for the first time.
 * @export
 */
export const AutomaticActivationTypes = {
    FirstPdpSession: 'FIRST_PDP_SESSION',
    FirstLocationUpdate: 'FIRST_LOCATION_UPDATE',
    Disabled: 'DISABLED'
} as const;
export type AutomaticActivationTypes = typeof AutomaticActivationTypes[keyof typeof AutomaticActivationTypes];


export function AutomaticActivationTypesFromJSON(json: any): AutomaticActivationTypes {
    return AutomaticActivationTypesFromJSONTyped(json, false);
}

export function AutomaticActivationTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomaticActivationTypes {
    return json as AutomaticActivationTypes;
}

export function AutomaticActivationTypesToJSON(value?: AutomaticActivationTypes | null): any {
    return value as any;
}

