import React from "react";
import { Box, Flex, SimpleGrid, TabPanel, VStack } from "@chakra-ui/react";
import { SimCard } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import SimCardNetworkStatePaper from "./SimCardNetworkStatePaper";
import SimCardSummaryPaper from "./SimCardSummaryPaper";
import SimCardUsagePaper from "./SimCardUsagePaper";
import SimCardLabelsPaper from "./SimCardLabelsPaper";

export interface SimCardDetailsPanelProps {
    query: UseQueryResult<SimCard>;
}

const SimCardDetailsPanel = ({ query: { data: value, isLoading } }: SimCardDetailsPanelProps) => {
    return (
        <TabPanel w={"100%"}>
            <VStack w={"100%"} align={"stretch"} gap={6}>
                <Flex flexGrow={1} gap={6} direction={"row"} align={"stretch"}>
                    <Box minW={"100%"}>
                        <SimCardSummaryPaper isLoading={isLoading} simCard={value} />
                    </Box>
                </Flex>
                <SimpleGrid spacing={4} templateColumns="2fr 1fr">
                    <SimCardNetworkStatePaper simCard={value} />
                    <SimCardLabelsPaper simCard={value} />
                </SimpleGrid>
                <SimCardUsagePaper simCard={value} />
            </VStack>
        </TabPanel>
    );
};

export default SimCardDetailsPanel;
