import React from "react";
import {
    Action,
    ActionStateLabel,
    ActionTable,
    Page,
    PageTitle,
    SearchBar,
    SearchSelectInput,
    SearchSingleInput,
    singleInput,
    usePageNavigation,
    usePagination,
    useSearch,
} from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useApi } from "../../../hooks/useApi";
import { Action as CmpAction, ActionState } from "../../../api";
import useActionActor from "../../../hooks/useActionActor";
import { ActionClassifierLabels } from "../../../labels/ActionClassifierLabels";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";

const CmpActionListPage = () => {
    const { actionApi } = useApi();

    const { navigate } = usePageNavigation<Action>({ route: value => `/actions/${value.id}` });

    const { pageState, setPageState, resetPageState } = usePagination({
        pageIndex: 0,
        pageSize: 10,
    });
    const search = useSearch({
        config: {
            correlationId: singleInput<string>("Correlation ID"),
            actionState: singleInput<ActionState>("Action State", { display: value => ActionStateLabel[value] }),
        },
        onChange: resetPageState,
    });

    const { state } = search;
    const actionColumnHelper = createColumnHelper<CmpAction>();

    const { data: actions, isLoading } = useQuery(["actions", pageState, state], () =>
        actionApi.getActions({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
            correlationId: state?.correlationId?.value || undefined,
            actionState: state?.actionState?.value ? [state?.actionState?.value] : undefined,
        }),
    );

    const actionActor = useActionActor();

    return (
        <Page
            title={<PageTitle title={"Actions"} />}
            permissions={{ values: [Permission.action.read], deniedComponent: <RestrictedPage /> }}
        >
            <SearchBar
                defaultFilterInputId={"correlationId"}
                useSearch={search}
                filterInputs={[
                    {
                        id: "correlationId",
                        menuLabel: "Correlation ID",
                        inputComponent: (
                            <SearchSingleInput
                                registration={search.registerInput({ id: "correlationId" })}
                                placeholder={"Correlation ID"}
                            />
                        ),
                    },
                    {
                        id: "actionStates",
                        menuLabel: "Action State",
                        inputComponent: (
                            <SearchSelectInput
                                registration={search.registerInput({ id: "actionState" })}
                                items={Object.values(ActionState)}
                                placeholder={"Select State"}
                                autocompleteAbleMapping={state => ActionStateLabel[state as ActionState]}
                                displayMapping={state => ActionStateLabel[state as ActionState]}
                            />
                        ),
                    },
                ]}
            />
            <ActionTable
                sizing={"fit-page"}
                data={actions?.content || []}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: navigate,
                }}
                withPagination={{
                    pageSize: pageState.pageSize,
                    pageIndex: pageState.pageIndex,
                    onPaginationChange: setPageState,
                    totalRowCount: actions?.pageProperties?.totalElements || 0,
                    totalPageCount: actions?.pageProperties?.totalPages || 0,
                }}
                isLoading={isLoading}
                actionTypeColumn={
                    actionColumnHelper.accessor("actionClassifier", {
                        id: "actionClassifier",
                        header: "Type",
                        cell: row => ActionClassifierLabels[row.getValue() || "UNKNOWN"],
                    }) as ColumnDef<CmpAction>
                }
                actionActorResolvers={actionActor}
            />
        </Page>
    );
};

export default CmpActionListPage;
