/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingGroupDataUsageDataUsageInner } from './BillingGroupDataUsageDataUsageInner';
import {
    BillingGroupDataUsageDataUsageInnerFromJSON,
    BillingGroupDataUsageDataUsageInnerFromJSONTyped,
    BillingGroupDataUsageDataUsageInnerToJSON,
} from './BillingGroupDataUsageDataUsageInner';

/**
 * 
 * @export
 * @interface BillingGroupDataUsage
 */
export interface BillingGroupDataUsage {
    /**
     * 
     * @type {string}
     * @memberof BillingGroupDataUsage
     */
    billingGroupId: string;
    /**
     * 
     * @type {number}
     * @memberof BillingGroupDataUsage
     */
    totalBytes: number;
    /**
     * 
     * @type {number}
     * @memberof BillingGroupDataUsage
     */
    totalBillableBytes: number;
    /**
     * 
     * @type {Array<BillingGroupDataUsageDataUsageInner>}
     * @memberof BillingGroupDataUsage
     */
    dataUsage: Array<BillingGroupDataUsageDataUsageInner>;
}

/**
 * Check if a given object implements the BillingGroupDataUsage interface.
 */
export function instanceOfBillingGroupDataUsage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "billingGroupId" in value;
    isInstance = isInstance && "totalBytes" in value;
    isInstance = isInstance && "totalBillableBytes" in value;
    isInstance = isInstance && "dataUsage" in value;

    return isInstance;
}

export function BillingGroupDataUsageFromJSON(json: any): BillingGroupDataUsage {
    return BillingGroupDataUsageFromJSONTyped(json, false);
}

export function BillingGroupDataUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingGroupDataUsage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingGroupId': json['billingGroupId'],
        'totalBytes': json['totalBytes'],
        'totalBillableBytes': json['totalBillableBytes'],
        'dataUsage': ((json['dataUsage'] as Array<any>).map(BillingGroupDataUsageDataUsageInnerFromJSON)),
    };
}

export function BillingGroupDataUsageToJSON(value?: BillingGroupDataUsage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billingGroupId': value.billingGroupId,
        'totalBytes': value.totalBytes,
        'totalBillableBytes': value.totalBillableBytes,
        'dataUsage': ((value.dataUsage as Array<any>).map(BillingGroupDataUsageDataUsageInnerToJSON)),
    };
}

