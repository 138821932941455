import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useNotifications } from "@cobira/ui-library";

interface UseDownloadFileProps {
    readonly downloadFunction: () => Promise<Blob>;
    readonly fileName: string;
}

interface UseDownloadedFileReturn {
    readonly download: () => void;
    readonly ref: MutableRefObject<HTMLAnchorElement | null>;
    readonly fileName: string;
    readonly url: string | undefined;
}

export const useDownloadFile = ({ downloadFunction, fileName }: UseDownloadFileProps): UseDownloadedFileReturn => {
    const notifications = useNotifications();
    const ref = useRef<HTMLAnchorElement | null>(null);
    const [url, setFileUrl] = useState<string | undefined>();

    const download = useCallback(() => {
        downloadFunction()
            .then(file => setFileUrl(URL.createObjectURL(file)))
            .catch(error => {
                notifications.error(error);
            });
    }, [downloadFunction, notifications]);

    useEffect(() => {
        if (url) {
            ref.current?.click();
            URL.revokeObjectURL(url);
            setFileUrl(undefined);
        }
    }, [url]);

    return { download, ref, url, fileName };
};
