import React, { useMemo } from "react";
import { useApi } from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { NetworkInfo as NetworkInfoComponent } from "@cobira/ui-library";

interface NetworkInfoProps {
    plmn?: string | undefined;
}

const NetworkInfo = ({ plmn }: NetworkInfoProps) => {
    const { networkApi } = useApi();
    const { data: networks, isFetching } = useQuery(
        ["networks", plmn],
        () => networkApi.getNetworks({ plmn: plmn, pageNumber: 0, pageSize: 1 }),
        { enabled: plmn !== undefined },
    );

    const network = useMemo(() => networks?.content?.at(0), [networks]);

    return <NetworkInfoComponent plmn={plmn} networkName={network?.networkName} isLoading={isFetching} />;
};

export default NetworkInfo;
