/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Month } from './Month';
import {
    MonthFromJSON,
    MonthFromJSONTyped,
    MonthToJSON,
} from './Month';
import type { SmsUsageByPlmn } from './SmsUsageByPlmn';
import {
    SmsUsageByPlmnFromJSON,
    SmsUsageByPlmnFromJSONTyped,
    SmsUsageByPlmnToJSON,
} from './SmsUsageByPlmn';

/**
 * 
 * @export
 * @interface BillingGroupSmsUsageSmsUsageInner
 */
export interface BillingGroupSmsUsageSmsUsageInner {
    /**
     * A specific year between 2020 and 9999.
     * @type {number}
     * @memberof BillingGroupSmsUsageSmsUsageInner
     */
    year?: number;
    /**
     * 
     * @type {Month}
     * @memberof BillingGroupSmsUsageSmsUsageInner
     */
    month?: Month;
    /**
     * 
     * @type {Array<SmsUsageByPlmn>}
     * @memberof BillingGroupSmsUsageSmsUsageInner
     */
    plmns?: Array<SmsUsageByPlmn>;
}

/**
 * Check if a given object implements the BillingGroupSmsUsageSmsUsageInner interface.
 */
export function instanceOfBillingGroupSmsUsageSmsUsageInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BillingGroupSmsUsageSmsUsageInnerFromJSON(json: any): BillingGroupSmsUsageSmsUsageInner {
    return BillingGroupSmsUsageSmsUsageInnerFromJSONTyped(json, false);
}

export function BillingGroupSmsUsageSmsUsageInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingGroupSmsUsageSmsUsageInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': !exists(json, 'year') ? undefined : json['year'],
        'month': !exists(json, 'month') ? undefined : MonthFromJSON(json['month']),
        'plmns': !exists(json, 'plmns') ? undefined : ((json['plmns'] as Array<any>).map(SmsUsageByPlmnFromJSON)),
    };
}

export function BillingGroupSmsUsageSmsUsageInnerToJSON(value?: BillingGroupSmsUsageSmsUsageInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'month': MonthToJSON(value.month),
        'plmns': value.plmns === undefined ? undefined : ((value.plmns as Array<any>).map(SmsUsageByPlmnToJSON)),
    };
}

