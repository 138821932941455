import React from "react";
import { SearchSelectInput, SearchSingleInputProps, SearchStateConfig } from "@cobira/ui-library";
import { NetworkState } from "../../../api";
import { NetworkStateLabels } from "../../../labels/NetworkStateLabels";

export interface SearchSelectSimCardNetworkStateInputProps<
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
> extends Omit<SearchSingleInputProps<NetworkState, K, TSearchStateConfig>, "label"> {}

export const SearchSelectSimCardNetworkStateInput = <
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
>({
    registration,
    placeholder = "SIM Card State",
}: SearchSelectSimCardNetworkStateInputProps<K, TSearchStateConfig>) => {
    return (
        <SearchSelectInput
            registration={registration}
            items={Object.values(NetworkState)}
            placeholder={placeholder}
            autocompleteAbleMapping={value => value}
            displayMapping={value => NetworkStateLabels[value]}
        />
    );
};
