import React from "react";
import { Heading, HStack } from "@chakra-ui/react";
import { Metric, Month, MonthLabel, sum } from "@cobira/ui-library";

export interface SimCardSmsUsagePieChartHeaderProps {
    month: Month;
    year: number;
    data: Metric[];
}

const SimCardSmsUsagePieChartHeader = ({ year, month, data }: SimCardSmsUsagePieChartHeaderProps) => {
    const totalValue = data.map(metric => metric.value).reduce(sum, 0);
    return (
        <HStack w={"100%"}>
            <Heading size={"md"}>{`${MonthLabel[month]} - ${year}`}</Heading>
            <Heading ml={"auto"} size={"md"}>
                {`Total SMS: ${totalValue} pcs`}
            </Heading>
        </HStack>
    );
};

export default SimCardSmsUsagePieChartHeader;
