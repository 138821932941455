import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { SimCard, SimCardActionItem } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { CobiraTable, DateTime, PlaceholderText, usePagination } from "@cobira/ui-library";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import ActionItemStateIndicator from "../../../../components/ActionItemStateIndicator/ActionItemStateIndicator";
import { ActorCell } from "../../../../components/Cells/ActorCell/ActorCell";
import { createColumnHelper } from "@tanstack/react-table";
import { ActionClassifierLabels } from "../../../../labels/ActionClassifierLabels";

interface SimCardActivityHistoryPanelProps {
    query: UseQueryResult<SimCard>;
}

const simCardActionItemColumn = createColumnHelper<SimCardActionItem>();
const COLUMNS = [
    simCardActionItemColumn.accessor("state", {
        id: "state",
        header: "State",
        cell: row => <ActionItemStateIndicator state={row.getValue()} withLabel />,
    }),
    simCardActionItemColumn.accessor("actionClassifier", {
        id: "actionType",
        header: "Type",
        cell: row => ActionClassifierLabels[row.getValue() || "UNKNOWN"],
    }),
    simCardActionItemColumn.accessor("actor", {
        id: "actor",
        header: "Actor",
        cell: row => <ActorCell actor={row.getValue()} />,
    }),
    simCardActionItemColumn.accessor("processedAt", {
        id: "processedAt",
        header: "Performed Date",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    simCardActionItemColumn.accessor("stateMessage", {
        id: "stateMessage",
        header: "Message",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
];

const SimCardActivityHistoryPanel = ({ query: { data: value } }: SimCardActivityHistoryPanelProps) => {
    const { simCardApi } = useApi();

    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { data: activities, isLoading: isLoadingActivity } = useQuery(
        ["simcards", value?.icc, "activity", pageState],
        () =>
            simCardApi.getSimCardActions({
                icc: value?.icc || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
            }),
        { enabled: value?.icc !== undefined },
    );

    return (
        <TabPanel w={"100%"}>
            <CobiraTable
                columns={COLUMNS}
                data={activities?.content || []}
                isLoading={isLoadingActivity}
                withPagination={{
                    pageSize: pageState.pageSize,
                    pageIndex: pageState.pageIndex,
                    onPaginationChange: setPageState,
                    totalRowCount: activities?.pageProperties?.totalElements || 0,
                    totalPageCount: activities?.pageProperties?.totalPages || 0,
                }}
            />
        </TabPanel>
    );
};

export default SimCardActivityHistoryPanel;
