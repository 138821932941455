import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { LocationUpdate, SimCard } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import {
    CobiraTable,
    CountryInfo,
    DateTime,
    getCountry,
    LocationUpdateStatusIndicator,
    PlaceholderText,
    usePagination,
} from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import NetworkInfo from "../../../../components/NetworkInfo/NetworkInfo";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { LocationUpdateTypeLabels } from "../../../../labels/LocationUpdateLabels";
import { createColumnHelper } from "@tanstack/react-table";

interface SimCardLocationUpdatesPanelProps {
    query: UseQueryResult<SimCard>;
}

const locationUpdateColumn = createColumnHelper<LocationUpdate>();
const COLUMNS = [
    locationUpdateColumn.accessor("status", {
        id: "status",
        header: "Status",
        cell: row => <LocationUpdateStatusIndicator status={row.getValue()} withLabel />,
    }),
    locationUpdateColumn.accessor("imsi", {
        id: "imsi",
        header: "IMSI",
        cell: row => row.getValue(),
    }),
    locationUpdateColumn.accessor("plmn", {
        id: "network",
        header: "Network",
        cell: row => <NetworkInfo plmn={row.getValue()} />,
    }),
    locationUpdateColumn.accessor("country", {
        id: "country",
        header: "Country",
        cell: row => <CountryInfo country={getCountry(row.getValue())} />,
    }),
    locationUpdateColumn.accessor("networkTechnology", {
        id: "networkTechnology",
        header: "Type",
        cell: row => <PlaceholderText text={LocationUpdateTypeLabels[row.getValue()]} />,
    }),
    locationUpdateColumn.accessor("recordedAt", {
        id: "recordedAt",
        header: "Recorded At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
];

const SimCardLocationUpdatesPanel = ({ query: { data: value } }: SimCardLocationUpdatesPanelProps) => {
    const { simCardApi } = useApi();
    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { data: dataSessions, isLoading: isLoadingDataSessions } = useQuery(
        ["simcards", value?.icc, "locationupdates", pageState],
        () =>
            simCardApi.getSimCardLocationUpdates({
                icc: value?.icc || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
            }),
        { enabled: value?.icc !== undefined },
    );

    return (
        <TabPanel w={"100%"}>
            <CobiraTable
                columns={COLUMNS}
                data={dataSessions?.content || []}
                isLoading={isLoadingDataSessions}
                withPagination={{
                    pageIndex: pageState.pageIndex,
                    pageSize: pageState.pageSize,
                    onPaginationChange: setPageState,
                    totalRowCount: dataSessions?.pageProperties?.totalElements || 0,
                    totalPageCount: dataSessions?.pageProperties?.totalPages || 0,
                }}
            />
        </TabPanel>
    );
};

export default SimCardLocationUpdatesPanel;
