import React from "react";
import { useApi } from "../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { Action, ActionType } from "../../../api";
import { ActionDetailsPage, ActionItem, HasPermission } from "@cobira/ui-library";
import useActionActor from "../../../hooks/useActionActor";
import { ActionClassifierLabels } from "../../../labels/ActionClassifierLabels";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";

const resolveActionType = (action?: Action) => {
    const actionType = ActionClassifierLabels[action?.actionClassifier || "UNKNOWN"];
    return actionType != ActionType.Unknown ? actionType : "UNKNOWN";
};

const CmpActionDetailPage = () => {
    const { actionId } = useParams();
    const { actionApi } = useApi();
    const actionActor = useActionActor();

    const { data: action, isLoading: isActionLoading } = useQuery(
        ["action", actionId],
        () => actionApi.getAction({ actionId: actionId || "" }),
        {
            enabled: !!actionId,
        },
    );

    const { data: actionItems, isLoading: isActionItemsLoading } = useQuery(
        ["action", actionId, "items"],
        () => actionApi.getActionItems({ actionId: actionId || "", pageSize: 100, pageNumber: 0 }),
        {
            enabled: !!actionId,
        },
    );

    const mappedActionItems = actionItems?.content?.map<ActionItem>(actionItem => ({
        itemIdentifier: actionItem.itemIdentifier,
        itemState: actionItem.itemState,
        processedAt: actionItem.processedAt,
        itemType: "SIM_CARD", // TODO expose the item type in the API
        stateMessage: actionItem.stateMessage,
    }));

    return (
        <HasPermission values={[Permission.action.read]} type={"allOf"} deniedComponent={<RestrictedPage />}>
            <ActionDetailsPage
                isLoading={isActionLoading || isActionItemsLoading}
                action={action}
                actionTypeResolver={resolveActionType}
                actionActorResolvers={actionActor}
                actionItems={mappedActionItems}
            />
        </HasPermission>
    );
};

export default CmpActionDetailPage;
