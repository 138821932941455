import React, { FC } from "react";
import {
    CobiraBarChartPaper,
    MetricLabelFormatters,
    MetricsProvider,
    newRequest,
    useMetadata,
} from "@cobira/ui-library";
import { useMetricsResolver } from "../../../hooks/useMetricsResolver";
import { subMonths } from "date-fns";

interface CustomerActiveSimsChartProps {}

export const CustomerActiveSimsChart: FC<CustomerActiveSimsChartProps> = () => {
    const metadata = useMetadata();
    const metricResolver = useMetricsResolver();
    const thisMonth = new Date();
    const twelveMonthsAgo = subMonths(thisMonth, 12);

    const requests = {
        activeSims: newRequest({
            bucket: "MONTHLY",
            dataPoint: "ACTIVE_SIM",
            group: "CUSTOMER",
            groupId: metadata.tenantId,
            fromDateTime: twelveMonthsAgo,
            toDateTime: thisMonth,
        }),
        totalSims: newRequest({
            bucket: "MONTHLY",
            dataPoint: "ASSIGNED_SIM",
            group: "CUSTOMER",
            groupId: metadata.tenantId,
            fromDateTime: twelveMonthsAgo,
            toDateTime: thisMonth,
        }),
    };

    return (
        <MetricsProvider requests={requests} resolver={metricResolver}>
            {data => (
                <CobiraBarChartPaper
                    title={"SIM metrics"}
                    chartContext={"Last 12 months"}
                    bars={[
                        { dataSet: data => data.activeSims.value, dataSetName: "Active SIMs" },
                        { dataSet: data => data.totalSims.value, dataSetName: "Total SIMs" },
                    ]}
                    data={data}
                    chartConfig={{
                        xAxisValueLabel: data => MetricLabelFormatters.yearAndMonth(data.totalSims),
                    }}
                />
            )}
        </MetricsProvider>
    );
};
