import React, { useMemo, useState } from "react";
import {
    Box,
    FormControl,
    FormLabel,
    HStack,
    Switch,
    Tab,
    TabList,
    TabPanels,
    Tabs,
    useBoolean,
    VStack,
} from "@chakra-ui/react";
import { AutoCompleteInput, intToMonth, Month, MonthInput, Paper } from "@cobira/ui-library";
import SimCardDataUsagePanel from "./SimCardDataUsagePanel";
import SimCardSmsUsagePanel from "./SimCardSmsUsagePanel";
import { SimCard } from "../../../../api";

export interface SimCardUsagePaperProps {
    simCard?: SimCard;
}

const SimCardUsagePaper = ({ simCard }: SimCardUsagePaperProps) => {
    const now = new Date();
    const [showAllUsage, { toggle: toggleShowAllUsage }] = useBoolean(true);
    const [selectedUsageYear, setSelectedUsageYear] = useState<number | null>(now.getFullYear());
    const [selectedUsageMonth, setSelectedUsageMonth] = useState<Month | null>(intToMonth(now.getMonth()));
    const yearOptions = useMemo(() => {
        const currentYear = new Date().getFullYear();
        return [currentYear, currentYear - 1, currentYear - 2];
    }, []);

    return (
        <Paper header={"Usage"} withDivider>
            <Tabs>
                <VStack w={"100%"} gap={2}>
                    <HStack w={"100%"}>
                        <TabList>
                            <Tab>Data</Tab>
                            <Tab>SMS</Tab>
                        </TabList>
                        <FormControl display="flex" alignItems="center">
                            <Switch ml="auto" id="all-usage" onChange={toggleShowAllUsage} isChecked={showAllUsage} />
                            <FormLabel htmlFor="all-usage" ml="2" mb="0">
                                Show all usage
                            </FormLabel>
                        </FormControl>
                        <Box minW={"fit-content"}>
                            <AutoCompleteInput
                                isDisabled={showAllUsage}
                                placeholder={"Year"}
                                value={selectedUsageYear}
                                onChange={setSelectedUsageYear}
                                isMulti={false}
                                isClearable={false}
                                items={yearOptions}
                                displayMapping={year => `${year}`}
                                autocompleteAbleMapping={year => `${year}`}
                            />
                        </Box>
                        <Box minW={"fit-content"}>
                            <MonthInput
                                isClearable={false}
                                isDisabled={showAllUsage}
                                value={selectedUsageMonth}
                                onChange={setSelectedUsageMonth}
                                isMulti={false}
                            />
                        </Box>
                    </HStack>
                    <TabPanels>
                        <SimCardDataUsagePanel
                            simCard={simCard}
                            showAllUsage={showAllUsage}
                            selectedUsageYear={selectedUsageYear}
                            selectedUsageMonth={selectedUsageMonth}
                        />
                        <SimCardSmsUsagePanel
                            simCard={simCard}
                            showAllUsage={showAllUsage}
                            selectedUsageMonth={selectedUsageMonth}
                            selectedUsageYear={selectedUsageYear}
                        />
                    </TabPanels>
                </VStack>
            </Tabs>
        </Paper>
    );
};

export default SimCardUsagePaper;
