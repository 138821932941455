import { LocationUpdateNetworkTechnologyEnum, LocationUpdateStatus } from "../api";

export const LocationUpdateStatusLabels = {
    [LocationUpdateStatus.Accepted]: "Accepted",
    [LocationUpdateStatus.Rejected]: "Rejected",
    [LocationUpdateStatus.Unknown]: "Unknown",
};

export const LocationUpdateTypeLabels = {
    [LocationUpdateNetworkTechnologyEnum.PacketSwitching]: "Packet Switching",
    [LocationUpdateNetworkTechnologyEnum.CircuitSwitching]: "Circuit Switching",
    [LocationUpdateNetworkTechnologyEnum.Unknown]: "Unknown",
};
