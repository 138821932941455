import React, { useCallback } from "react";
import { BasicModal, HasPermission, useNotifications } from "@cobira/ui-library";
import InviteUserForm, { InviteUserFormType } from "../../../forms/InviteUserForm/InviteUserForm";
import { useApi } from "../../../hooks/useApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { User, UserApiCreateUserRequest } from "../../../api";
import { Permission } from "../../../utils/Permissions";

export interface CreateWebhookModalProps {
    isOpen: boolean;
    onClose: (created: boolean) => void;
}

const InviteUserModal = ({ isOpen, onClose }: CreateWebhookModalProps) => {
    const { roleApi, userApi } = useApi();
    const notifications = useNotifications();

    const { data: roles } = useQuery(["roles"], () => roleApi.getRoles());

    const inviteUser = useMutation<User, Error, UserApiCreateUserRequest>(variables => userApi.createUser(variables), {
        onSuccess: user => {
            notifications.success(`Invited ${user.name}`);
            onClose(true);
        },
        onError: error => notifications.error(error?.message),
    });

    const handleInviteUserFormSubmit = useCallback(
        (form: InviteUserFormType) => {
            inviteUser.mutate({
                createUser: {
                    name: form.name,
                    email: form.email,
                    roles: [form.role.id],
                },
            });
        },
        [inviteUser],
    );

    return (
        <HasPermission values={[Permission.user.write]} type={"allOf"}>
            <BasicModal isOpen={isOpen} onClose={() => onClose(false)} title={"User Invitation"}>
                <InviteUserForm onSubmit={handleInviteUserFormSubmit} availableRoles={roles || []} />
            </BasicModal>
        </HasPermission>
    );
};

export default InviteUserModal;
