import React, { FC } from "react";
import { useApi } from "../../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { formatToKSyntax, InfoCard, LoadingSkeleton, SimCardIcon } from "@cobira/ui-library";

interface TotalSimsInfoCardProps {}

export const TotalSimsInfoCard: FC<TotalSimsInfoCardProps> = () => {
    const { simCardApi } = useApi();

    const { data: simCards, isLoading: isLoadingSimCards } = useQuery(["simCards"], () =>
        simCardApi.getSimCards({ pageSize: 1, pageNumber: 0 }),
    );

    const LoadingComponent = <LoadingSkeleton />;
    const TotalSimsComponent = formatToKSyntax(simCards?.pageProperties?.totalElements ?? 0);
    const ValueComponent = isLoadingSimCards ? LoadingComponent : TotalSimsComponent;

    return <InfoCard title={"Total SIMs"} value={ValueComponent} icon={SimCardIcon} />;
};
