import React, { useCallback } from "react";
import { ConfirmModal, HasPermission, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { User, UserApiDeleteUserRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";
import { Permission } from "../../../utils/Permissions";

export interface ConfirmDeleteUserModalProps {
    onClose: () => void;
    user?: User;
}

const ConfirmDeleteUserModal = ({ onClose, user }: ConfirmDeleteUserModalProps) => {
    const { userApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const deleteUser = useMutation<void, Error, UserApiDeleteUserRequest>(variables => userApi.deleteUser(variables));
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && user?.id) {
                deleteUser.mutate(
                    { userId: user.id },
                    {
                        onSuccess: () => {
                            notifications.success(`${user.email} has been blocked`);
                            queryClient.invalidateQueries(["users", user?.id]);
                        },
                        onError: error => notifications.error(error?.message),
                    },
                );
            }
            onClose();
        },
        [user, deleteUser, notifications, onClose, queryClient],
    );

    return (
        <HasPermission values={[Permission.user.write]} type={"allOf"}>
            <ConfirmModal
                onClose={handleOnClose}
                isOpen={!!user}
                title={"Block User"}
                description={
                    <Container>
                        <Text>Are you sure you want to block user?</Text>
                        <Text as={"b"}>{user?.name}</Text>
                    </Container>
                }
            />
        </HasPermission>
    );
};

export default ConfirmDeleteUserModal;
