/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UsageAllowanceTypes = {
    Data: 'DATA',
    Sms: 'SMS'
} as const;
export type UsageAllowanceTypes = typeof UsageAllowanceTypes[keyof typeof UsageAllowanceTypes];


export function UsageAllowanceTypesFromJSON(json: any): UsageAllowanceTypes {
    return UsageAllowanceTypesFromJSONTyped(json, false);
}

export function UsageAllowanceTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsageAllowanceTypes {
    return json as UsageAllowanceTypes;
}

export function UsageAllowanceTypesToJSON(value?: UsageAllowanceTypes | null): any {
    return value as any;
}

