import { Box } from "@chakra-ui/react";
import { Loading } from "@cobira/ui-library";
import React from "react";
import { BillingGroup } from "../../../api";
import { BillingGroupActiveSimsChart } from "../../../components/Charts/BillingGroupActiveSimsChart/BillingGroupActiveSimsChart";

export interface CustomerSimCountPaperProps {
    billingGroup?: BillingGroup;
}

const BillingGroupSimCountPaper = ({ billingGroup }: CustomerSimCountPaperProps) => {
    if (!billingGroup) {
        return <Loading />;
    }

    return (
        <Box w={"100%"} h={"600px"}>
            <BillingGroupActiveSimsChart billingGroupId={billingGroup.id} />
        </Box>
    );
};

export default BillingGroupSimCountPaper;
