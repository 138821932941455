import React from "react";
import { ErrorPage } from "@cobira/ui-library";

const RestrictedPage = () => {
    return (
        <ErrorPage
            code={"403"}
            title={"Restricted"}
            description={
                "You do not have access to this page, please contact members of your team if you think this is wrong"
            }
            escapeDescription={"Go to home"}
            escapePath={"/"}
        />
    );
};

export default RestrictedPage;
