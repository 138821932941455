import { SimCardActionRequestFilter } from "../../../api";
import ChangeStateSimCardsModal from "../ChangeStateSimCardsModal/ChangeStateSimCardsModal";
import SetImeiLockStateModal from "../SetImeiLockStateModal/SetImeiLockStateModal";
import SendSMSModal from "../SendSMSModal/SendSMSModal";
import AssignUsagePackageModal from "../AssignUsagePackageModal/AssignUsagePackageModal";
import RevokeUsagePackageModal from "../RevokeUsagePackageModal/RevokeUsagePackageModal";
import React from "react";
import SetImeiLockModal from "../SetImeiLockModal/SetImeiLockModal";
import { HasPermission } from "@cobira/ui-library";
import { Permission } from "../../../utils/Permissions";

export type SimActionType =
    | "activate"
    | "suspend"
    | "reset"
    | "imei-lock-state"
    | "set-imei-lock"
    | "send-sms"
    | "assign-usage-package"
    | "revoke-usage-package";

export interface SimCardActionModalsProps {
    actionType: SimActionType | null;
    filter: SimCardActionRequestFilter;
    onClose: () => void;
}

const SimCardActionModals = ({ actionType, filter, onClose }: SimCardActionModalsProps) => {
    if (actionType === null) {
        return null;
    }

    const renderModal = () => {
        switch (actionType) {
            case "activate":
                return <ChangeStateSimCardsModal filter={filter} isOpen onClose={onClose} targetState={"ACTIVATE"} />;
            case "suspend":
                return <ChangeStateSimCardsModal filter={filter} isOpen onClose={onClose} targetState={"SUSPEND"} />;
            case "reset":
                return <ChangeStateSimCardsModal filter={filter} isOpen onClose={onClose} targetState={"RESET"} />;
            case "imei-lock-state":
                return <SetImeiLockStateModal filter={filter} isOpen onClose={onClose} />;
            case "set-imei-lock":
                if (filter.iccSet?.size === 1) {
                    return <SetImeiLockModal icc={filter.iccSet.values().next().value} isOpen onClose={onClose} />;
                } else if (filter.iccLowerBound && filter.iccLowerBound == filter.iccUpperBound) {
                    return <SetImeiLockModal icc={filter.iccLowerBound} isOpen onClose={onClose} />;
                } else {
                    return null;
                }
            case "assign-usage-package":
                return <AssignUsagePackageModal filter={filter} isOpen onClose={onClose} />;
            case "send-sms":
                return <SendSMSModal filter={filter} isOpen onClose={onClose} />;
            case "revoke-usage-package":
                return <RevokeUsagePackageModal filter={filter} isOpen onClose={onClose} />;
        }
    };

    return <HasPermission values={[Permission.simCard.write]}>{renderModal()}</HasPermission>;
};

export default SimCardActionModals;
