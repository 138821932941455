/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AutomaticActivationTypes } from './AutomaticActivationTypes';
import {
    AutomaticActivationTypesFromJSON,
    AutomaticActivationTypesFromJSONTyped,
    AutomaticActivationTypesToJSON,
} from './AutomaticActivationTypes';
import type { UsageAllowance } from './UsageAllowance';
import {
    UsageAllowanceFromJSON,
    UsageAllowanceFromJSONTyped,
    UsageAllowanceToJSON,
} from './UsageAllowance';

/**
 * 
 * @export
 * @interface UsagePackageType
 */
export interface UsagePackageType {
    /**
     * 
     * @type {string}
     * @memberof UsagePackageType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UsagePackageType
     */
    customName: string;
    /**
     * 
     * @type {string}
     * @memberof UsagePackageType
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UsagePackageType
     */
    supportedCountries: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UsagePackageType
     */
    packageDuration: string;
    /**
     * 
     * @type {AutomaticActivationTypes}
     * @memberof UsagePackageType
     */
    activationType: AutomaticActivationTypes;
    /**
     * 
     * @type {Array<UsageAllowance>}
     * @memberof UsagePackageType
     */
    usageAllowances: Array<UsageAllowance>;
    /**
     * 
     * @type {Date}
     * @memberof UsagePackageType
     */
    deletedAt?: Date;
}

/**
 * Check if a given object implements the UsagePackageType interface.
 */
export function instanceOfUsagePackageType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "customName" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "supportedCountries" in value;
    isInstance = isInstance && "packageDuration" in value;
    isInstance = isInstance && "activationType" in value;
    isInstance = isInstance && "usageAllowances" in value;

    return isInstance;
}

export function UsagePackageTypeFromJSON(json: any): UsagePackageType {
    return UsagePackageTypeFromJSONTyped(json, false);
}

export function UsagePackageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsagePackageType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customName': json['customName'],
        'description': json['description'],
        'supportedCountries': json['supportedCountries'],
        'packageDuration': json['packageDuration'],
        'activationType': AutomaticActivationTypesFromJSON(json['activationType']),
        'usageAllowances': ((json['usageAllowances'] as Array<any>).map(UsageAllowanceFromJSON)),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
    };
}

export function UsagePackageTypeToJSON(value?: UsagePackageType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customName': value.customName,
        'description': value.description,
        'supportedCountries': value.supportedCountries,
        'packageDuration': value.packageDuration,
        'activationType': AutomaticActivationTypesToJSON(value.activationType),
        'usageAllowances': ((value.usageAllowances as Array<any>).map(UsageAllowanceToJSON)),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}

