import React from "react";
import { Heading, HStack } from "@chakra-ui/react";
import { convertData, Metric, Month, MonthLabel, sum } from "@cobira/ui-library";

export interface SimCardDataUsagePieChartHeaderProps {
    month: Month;
    year: number;
    data: Metric[];
}

const SimCardDataUsagePieChartHeader = ({ year, month, data }: SimCardDataUsagePieChartHeaderProps) => {
    const totalValue = data.map(metric => metric.value).reduce(sum, 0);
    return (
        <HStack w={"100%"}>
            <Heading size={"md"}>{`${MonthLabel[month]} - ${year}`}</Heading>
            <Heading ml={"auto"} size={"md"}>
                {`Total data used: ${convertData(totalValue).toString()}`}
            </Heading>
        </HStack>
    );
};

export default SimCardDataUsagePieChartHeader;
