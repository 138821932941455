import React from "react";
import { Page, PageBackButton, PageTitle } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import UsagePackageTypeDetails from "./UsagePackageTypeDetails/UsagePackageTypeDetails";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";

const UsagePackageTypeDetailPage = () => {
    const { usagePackageApi } = useApi();
    const { usagePackageTypeId } = useParams();

    const usagePackageTypeQuery = useQuery(
        ["usagepackagetypes", usagePackageTypeId],
        () => usagePackageApi.getUsagePackageType({ usagePackageTypeId: usagePackageTypeId || "" }),
        {
            enabled: !!usagePackageTypeId,
        },
    );

    return (
        <Page
            title={<PageTitle title={usagePackageTypeQuery?.data?.customName || "Usage Package Type"} />}
            navigation={<PageBackButton text={"Back to packages"} path={"/usagepackagetypes"} />}
            permissions={{ values: [Permission.usagePackage.read], deniedComponent: <RestrictedPage /> }}
        >
            <UsagePackageTypeDetails query={usagePackageTypeQuery} />
        </Page>
    );
};

export default UsagePackageTypeDetailPage;
