/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimCardNetworkStateNetwork
 */
export interface SimCardNetworkStateNetwork {
    /**
     * 
     * @type {string}
     * @memberof SimCardNetworkStateNetwork
     */
    plmn?: string;
    /**
     * 
     * @type {string}
     * @memberof SimCardNetworkStateNetwork
     */
    country?: string;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStateNetwork
     */
    recordedAt?: Date;
}

/**
 * Check if a given object implements the SimCardNetworkStateNetwork interface.
 */
export function instanceOfSimCardNetworkStateNetwork(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardNetworkStateNetworkFromJSON(json: any): SimCardNetworkStateNetwork {
    return SimCardNetworkStateNetworkFromJSONTyped(json, false);
}

export function SimCardNetworkStateNetworkFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardNetworkStateNetwork {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plmn': !exists(json, 'plmn') ? undefined : json['plmn'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'recordedAt': !exists(json, 'recordedAt') ? undefined : (new Date(json['recordedAt'])),
    };
}

export function SimCardNetworkStateNetworkToJSON(value?: SimCardNetworkStateNetwork | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plmn': value.plmn,
        'country': value.country,
        'recordedAt': value.recordedAt === undefined ? undefined : (value.recordedAt.toISOString()),
    };
}

