/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimCardActionRequestFilter } from './SimCardActionRequestFilter';
import {
    SimCardActionRequestFilterFromJSON,
    SimCardActionRequestFilterFromJSONTyped,
    SimCardActionRequestFilterToJSON,
} from './SimCardActionRequestFilter';

/**
 * Request to assign a usage package to a SIM card
 * 
 * The `simCardFilter` property will replace the individual filter parameters over time,
 * we encourage that integrations start using the single filter property instead.
 * @export
 * @interface ManageUsagePackage
 */
export interface ManageUsagePackage {
    /**
     * 
     * @type {string}
     * @memberof ManageUsagePackage
     */
    usagePackageTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof ManageUsagePackage
     */
    usagePackageManagementAction?: ManageUsagePackageUsagePackageManagementActionEnum;
    /**
     * 
     * @type {SimCardActionRequestFilter}
     * @memberof ManageUsagePackage
     */
    simCardFilter: SimCardActionRequestFilter;
}


/**
 * @export
 */
export const ManageUsagePackageUsagePackageManagementActionEnum = {
    Assign: 'ASSIGN',
    Revoke: 'REVOKE'
} as const;
export type ManageUsagePackageUsagePackageManagementActionEnum = typeof ManageUsagePackageUsagePackageManagementActionEnum[keyof typeof ManageUsagePackageUsagePackageManagementActionEnum];


/**
 * Check if a given object implements the ManageUsagePackage interface.
 */
export function instanceOfManageUsagePackage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "usagePackageTypeId" in value;
    isInstance = isInstance && "simCardFilter" in value;

    return isInstance;
}

export function ManageUsagePackageFromJSON(json: any): ManageUsagePackage {
    return ManageUsagePackageFromJSONTyped(json, false);
}

export function ManageUsagePackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManageUsagePackage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usagePackageTypeId': json['usagePackageTypeId'],
        'usagePackageManagementAction': !exists(json, 'usagePackageManagementAction') ? undefined : json['usagePackageManagementAction'],
        'simCardFilter': SimCardActionRequestFilterFromJSON(json['simCardFilter']),
    };
}

export function ManageUsagePackageToJSON(value?: ManageUsagePackage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usagePackageTypeId': value.usagePackageTypeId,
        'usagePackageManagementAction': value.usagePackageManagementAction,
        'simCardFilter': SimCardActionRequestFilterToJSON(value.simCardFilter),
    };
}

