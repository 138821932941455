/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Automated behaviour of the IMEI lock when a violation is detected.
 * When `SUSPEND_SIM_CARD` is selected, SIM cards that violate the IMEI locks will be suspended when discovered.
 * @export
 */
export const ImeiLockViolationBehaviour = {
    SuspendSimCard: 'SUSPEND_SIM_CARD',
    Ignore: 'IGNORE'
} as const;
export type ImeiLockViolationBehaviour = typeof ImeiLockViolationBehaviour[keyof typeof ImeiLockViolationBehaviour];


export function ImeiLockViolationBehaviourFromJSON(json: any): ImeiLockViolationBehaviour {
    return ImeiLockViolationBehaviourFromJSONTyped(json, false);
}

export function ImeiLockViolationBehaviourFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImeiLockViolationBehaviour {
    return json as ImeiLockViolationBehaviour;
}

export function ImeiLockViolationBehaviourToJSON(value?: ImeiLockViolationBehaviour | null): any {
    return value as any;
}

