import React from "react";
import { PlaceholderText } from "@cobira/ui-library";
import { SkeletonText } from "@chakra-ui/react";
import { useApi } from "../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { ActionActor } from "../../../api";

export interface ActorCellProps {
    actor?: ActionActor;
}

export const ActorCell = ({ actor }: ActorCellProps) => {
    const { userApi, integrationApi } = useApi();
    const { data: user, isLoading: isLoadingUser } = useQuery(
        ["users", actor],
        () => userApi.getUserDetails({ userId: actor?.id || "" }),
        { enabled: actor !== undefined && actor.type === "USER" },
    );

    const { data: integrations } = useQuery(["integrations"], () => integrationApi.getIntegrations(), {
        enabled: actor !== undefined && actor.type === "APPLICATION",
    });

    const { data: accessToken, isLoading: isLoadingAccessToken } = useQuery(
        ["accesstokens", actor],
        () =>
            integrationApi.getAccessToken({
                integrationId: integrations?.at(0)?.id || "",
                accessTokenId: actor?.id || "",
            }),
        { enabled: actor !== undefined && actor.type === "APPLICATION" && integrations?.at(0)?.id !== undefined },
    );

    if (!actor || !actor.id) {
        return <PlaceholderText text={null} />;
    }

    if (actor.type === "USER") {
        return (
            <SkeletonText isLoaded={!isLoadingUser}>
                <PlaceholderText text={user?.["name"]} />
            </SkeletonText>
        );
    } else if (actor.type === "APPLICATION") {
        return (
            <SkeletonText isLoaded={!isLoadingAccessToken}>
                <PlaceholderText text={accessToken?.name} />
            </SkeletonText>
        );
    } else {
        return <PlaceholderText text={null} />;
    }
};
