import React, { useEffect, useState } from "react";
import { TabPanel, VStack } from "@chakra-ui/react";
import { NetworkCredential, SimCard } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import { CobiraTable } from "@cobira/ui-library";
import NetworkStateIndicator from "../../../../components/NetworkStateIndicator/NetworkStateIndicator";
import NetworkCredentialDetails from "./NetworkCredentialDetails";
import { createColumnHelper } from "@tanstack/react-table";

export interface SimCardNetworkCredentialsPanelProps {
    query: UseQueryResult<SimCard>;
}

const networkCredentialColumn = createColumnHelper<NetworkCredential>();
const NETWORK_CREDENTIAL_COLUMNS = [
    networkCredentialColumn.accessor("networkState", {
        id: "state",
        header: "State",
        cell: row => <NetworkStateIndicator state={row.getValue()} withLabel />,
    }),
    networkCredentialColumn.accessor("imsi", {
        id: "imsi",
        header: "IMSI",
        cell: row => row.getValue(),
    }),
];

const SimCardNetworkCredentialsPanel = ({ query: { data: simCard } }: SimCardNetworkCredentialsPanelProps) => {
    const { simCardApi } = useApi();
    const [selectedNetworkCredential, setSelectedNetworkCredential] = useState<NetworkCredential | undefined>(
        undefined,
    );
    const { data: networkCredentials, isLoading: isLoadingNetworkCredentials } = useQuery(
        ["simcards", simCard?.icc, "networkcredentials"],
        () => simCardApi.getSimCardNetworkCredentials({ icc: simCard?.icc || "" }),
        { enabled: !!simCard?.icc },
    );

    useEffect(() => {
        if (networkCredentials && networkCredentials.length && !selectedNetworkCredential) {
            setSelectedNetworkCredential(networkCredentials[0]);
        }
    }, [networkCredentials, selectedNetworkCredential, setSelectedNetworkCredential]);

    return (
        <TabPanel>
            <VStack gap={2} align={"stretch"}>
                <CobiraTable
                    withRowClick={{
                        enableHoverStyle: true,
                        clickedRow: selectedNetworkCredential,
                        onRowClicked: setSelectedNetworkCredential,
                    }}
                    columns={NETWORK_CREDENTIAL_COLUMNS}
                    data={networkCredentials || []}
                    isLoading={isLoadingNetworkCredentials}
                />
                <NetworkCredentialDetails networkCredential={selectedNetworkCredential} />
            </VStack>
        </TabPanel>
    );
};

export default SimCardNetworkCredentialsPanel;
