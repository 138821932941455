import React from "react";
import { BasicModal, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { WebhookSubscription } from "../../../api";
import { List, ListItem, Text, VStack } from "@chakra-ui/react";
import { WebhookSubscriptionTypeLabels } from "../../../labels/WebhookSubscriptionTypeLabels";

export interface WebhookDetailsModalProps {
    webhook?: WebhookSubscription;
    onClose: () => void;
}

const WebhookDetailsModal = ({ onClose, webhook }: WebhookDetailsModalProps) => {
    return (
        <BasicModal isOpen={!!webhook} onClose={onClose} title={webhook?.name} modalProps={{ size: "xl" }}>
            <VStack w={"100%"} align={"stretch"}>
                <TextColumn heading={"Name"}>
                    <PlaceholderText text={webhook?.name} />
                </TextColumn>
                <TextColumn heading={"Description"}>
                    <PlaceholderText text={webhook?.description} />
                </TextColumn>
                <TextColumn heading={"Target"}>
                    <PlaceholderText text={webhook?.target} />
                </TextColumn>
                <TextColumn heading={"Subscribed Types"}>
                    <List>
                        {webhook?.subscribedTypes?.map(type => (
                            <ListItem key={type}>
                                <Text>{WebhookSubscriptionTypeLabels[type]}</Text>
                            </ListItem>
                        ))}
                    </List>
                </TextColumn>
            </VStack>
        </BasicModal>
    );
};

export default WebhookDetailsModal;
