import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { CobiraBaseProvider, CobiraToaster } from "@cobira/ui-library";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: 10_000,
        },
    },
});

root.render(
    <React.StrictMode>
        <CobiraBaseProvider envFileUrl="/branding/env.json">
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <CobiraToaster />
                    <App />
                </BrowserRouter>
            </QueryClientProvider>
        </CobiraBaseProvider>
    </React.StrictMode>,
);
