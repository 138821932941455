import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { ImeiLockState, ImeiLockViolationBehaviour } from "../../api";
import { ImeiLockStateLabels } from "../../labels/ImeiLockStateLabels";
import { ImeiLockViolationBehaviourLabels } from "../../labels/ImeiLockLockViolationBehaviourLabels";

const ImeiLockStateFormSchema = z.object({
    lockState: z.nativeEnum(ImeiLockState),
    violationBehaviour: z.nativeEnum(ImeiLockViolationBehaviour),
});

export type ImeiLockStateFormType = z.infer<typeof ImeiLockStateFormSchema>;

export interface ImeiLockStateFormProps {
    defaultValue?: ImeiLockStateFormType;
    onSubmit: (form: ImeiLockStateFormType) => void;
    onCancel?: () => void;
}

const ImeiLockStateForm = ({ defaultValue, onSubmit, onCancel }: ImeiLockStateFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        control,
    } = useForm<ImeiLockStateFormType>({
        resolver: zodResolver(ImeiLockStateFormSchema),
        defaultValues: defaultValue || undefined,
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Lock State"} error={errors?.lockState}>
                    <ControlledAutoCompleteSingleSelectInput
                        input={{
                            items: Object.values(ImeiLockState),
                            displayMapping: value => ImeiLockStateLabels[value],
                            autocompleteAbleMapping: value => value,
                            placeholder: "Choose Lock checking",
                        }}
                        control={{
                            name: "lockState",
                            control: control,
                        }}
                    />
                </FormField>
                <FormField label={"Violation Behaviour"} error={errors?.violationBehaviour}>
                    <ControlledAutoCompleteSingleSelectInput
                        input={{
                            items: Object.values(ImeiLockViolationBehaviour),
                            displayMapping: value => ImeiLockViolationBehaviourLabels[value],
                            autocompleteAbleMapping: value => value,
                            placeholder: "Choose Violation behaviour",
                        }}
                        control={{
                            name: "violationBehaviour",
                            control: control,
                        }}
                    />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Confirm
                </Button>
                {oncancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default ImeiLockStateForm;
