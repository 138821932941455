/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NetworkStateAction } from './NetworkStateAction';
import {
    NetworkStateActionFromJSON,
    NetworkStateActionFromJSONTyped,
    NetworkStateActionToJSON,
} from './NetworkStateAction';
import type { SimCardActionRequestFilter } from './SimCardActionRequestFilter';
import {
    SimCardActionRequestFilterFromJSON,
    SimCardActionRequestFilterFromJSONTyped,
    SimCardActionRequestFilterToJSON,
} from './SimCardActionRequestFilter';

/**
 * Network state request and supported arguments.
 * If no filtering is applied it means all sim cards will be targeted.
 * 
 * The `simCardFilter` property will replace the individual properties eventually and we encourage that integrations reflect this change.
 * @export
 * @interface NetworkStateRequest
 */
export interface NetworkStateRequest {
    /**
     * 
     * @type {NetworkStateAction}
     * @memberof NetworkStateRequest
     */
    networkStateAction: NetworkStateAction;
    /**
     * 
     * @type {SimCardActionRequestFilter}
     * @memberof NetworkStateRequest
     */
    simCardFilter: SimCardActionRequestFilter;
}

/**
 * Check if a given object implements the NetworkStateRequest interface.
 */
export function instanceOfNetworkStateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "networkStateAction" in value;
    isInstance = isInstance && "simCardFilter" in value;

    return isInstance;
}

export function NetworkStateRequestFromJSON(json: any): NetworkStateRequest {
    return NetworkStateRequestFromJSONTyped(json, false);
}

export function NetworkStateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkStateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'networkStateAction': NetworkStateActionFromJSON(json['networkStateAction']),
        'simCardFilter': SimCardActionRequestFilterFromJSON(json['simCardFilter']),
    };
}

export function NetworkStateRequestToJSON(value?: NetworkStateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'networkStateAction': NetworkStateActionToJSON(value.networkStateAction),
        'simCardFilter': SimCardActionRequestFilterToJSON(value.simCardFilter),
    };
}

