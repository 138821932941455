import React from "react";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { Hardware, SimCard, SimCardNetworkState, SimCardNetworkStateCellularPosition } from "../../../../api";
import { Grid, GridItem, Heading, HStack, TabPanel, VStack } from "@chakra-ui/react";
import {
    chakraIcon,
    CopyButton,
    getHumanizedDurationSinceDateString,
    ImeiInfo,
    InfoCard,
    InfoCards,
    LastUpdatedIcon,
    Loading,
    MapLocationPaper,
    NotFound,
    OnOffInfoCard,
    Paper,
    PlaceholderText,
    SignalStrength,
    SignalStrengthTypes,
    TextColumn,
} from "@cobira/ui-library";
import { useApi } from "../../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { faMicrochip, faTachographDigital } from "@fortawesome/pro-regular-svg-icons";
import { NetworkStateSignalStrength } from "../../../../components/NetworkState/NetworkStateSignalStrength/NetworkStateSignalStrength";
import { NetworkStateRadioTechnology } from "../../../../components/NetworkState/NetworkStateRadioTechnology/NetworkStateRadioTechnology";
import { NetworkStateCellId } from "../../../../components/NetworkState/NetworkStateCellId/NetworkStateCellId";
import { NetworkStateNetworkInfo } from "../../../../components/NetworkState/NetworkStateNetworkInfo/NetworkStateNetworkInfo";

export interface HardwareTabPanelProps {
    query: UseQueryResult<SimCard>;
}

const toMapPosition = (cellularPosition?: SimCardNetworkStateCellularPosition) => {
    if (cellularPosition && cellularPosition.latitude !== undefined && cellularPosition.longitude !== undefined) {
        return {
            latitude: cellularPosition.latitude,
            longitude: cellularPosition.longitude,
        };
    }
    return undefined;
};

const isMapLocationStale = (hardware: Hardware, networkState?: SimCardNetworkState) => {
    if (networkState && networkState.cellularPosition?.recordedAt && networkState.cell?.recordedAt) {
        return networkState.cell.recordedAt > networkState.cellularPosition.recordedAt;
    } else {
        return false;
    }
};

export const HardwareTabPanel = ({ query: { data: simCard } }: HardwareTabPanelProps) => {
    const { simCardApi } = useApi();

    const valueOrUnknown = (string?: string) => string ?? "N/A";

    const { data: hardware, isFetching: isLoadingHardware } = useQuery(
        ["simcards", simCard?.icc, "hardware"],
        () => simCardApi.getSimCardHardware({ icc: simCard?.icc || "" }),
        {
            enabled: !!simCard?.icc,
        },
    );

    const { data: networkState, isFetching: isLoadingNetworkState } = useQuery(
        ["simcards", simCard?.icc, "networkstate"],
        () => simCardApi.getSimCardNetworkState({ icc: simCard?.icc || "" }),
        { enabled: simCard?.icc !== undefined },
    );

    const NetworkStateSignalStrengthComponent = (
        signalStrengthType: SignalStrengthTypes,
        hardware: Hardware,
        simCard?: SimCard,
    ) => {
        if (hardware.connectionState === "CONNECTED") {
            return <NetworkStateSignalStrength simCard={simCard} signalStrengthType={signalStrengthType} />;
        } else {
            return <SignalStrength signal={Number.MAX_SAFE_INTEGER} type={signalStrengthType} withTooltip={false} />;
        }
    };

    if (isLoadingHardware) {
        return (
            <TabPanel>
                <Loading />
            </TabPanel>
        );
    }
    if (!hardware) {
        return (
            <TabPanel>
                <NotFound resource={"Device"} />
            </TabPanel>
        );
    }

    const lastUpdate = getHumanizedDurationSinceDateString(hardware.updatedAt, {
        units: ["h", "m"],
        justNowSeconds: 30,
        justNowTemplate: "A few moments ago",
    });

    return (
        <TabPanel>
            <VStack w={"100%"} align={"stretch"} spacing={6}>
                <InfoCards>
                    <OnOffInfoCard
                        title={"Status"}
                        tooltip={"Status"}
                        onStateText={"Online"}
                        offStateText={"Offline"}
                        state={hardware.connectionState === "CONNECTED"}
                    />
                    <InfoCard
                        title={"Device Model"}
                        tooltip={"Device Model"}
                        value={valueOrUnknown(hardware.model)}
                        icon={chakraIcon(faTachographDigital)}
                    />
                    <InfoCard
                        title={"Device Firmware"}
                        tooltip={"Device Firmware"}
                        value={valueOrUnknown(hardware.firmware)}
                        icon={chakraIcon(faMicrochip)}
                    />
                    <InfoCard
                        title={"Last Update"}
                        tooltip={`Last updated at ${hardware.updatedAt}`}
                        value={lastUpdate}
                        icon={LastUpdatedIcon}
                    />
                </InfoCards>
                <Grid templateRows={"1fr"} templateColumns="repeat(1, 1fr)" columnGap={4} rowGap={4}>
                    <Paper header={"Signal Conditions"} withDivider>
                        <Grid templateColumns="auto auto auto auto auto" gap="4">
                            <TextColumn
                                heading={
                                    <HStack>
                                        <Heading size={"sm"}>Signal Strength</Heading>
                                    </HStack>
                                }
                                grid
                                isLoading={isLoadingHardware}
                            >
                                {NetworkStateSignalStrengthComponent(SignalStrengthTypes.RSSI, hardware, simCard)}
                            </TextColumn>
                            <TextColumn
                                heading={
                                    <HStack>
                                        <Heading size={"sm"}>Signal Quality</Heading>
                                    </HStack>
                                }
                                grid
                                isLoading={isLoadingHardware}
                            >
                                {NetworkStateSignalStrengthComponent(SignalStrengthTypes.RSRQ, hardware, simCard)}
                            </TextColumn>
                            <TextColumn
                                heading={
                                    <HStack>
                                        <Heading size={"sm"}>Network</Heading>
                                    </HStack>
                                }
                                grid
                                isLoading={isLoadingHardware}
                            >
                                {hardware.connectionState === "CONNECTED" ? (
                                    <NetworkStateNetworkInfo simCard={simCard} />
                                ) : (
                                    <PlaceholderText text={undefined} />
                                )}
                            </TextColumn>
                            <TextColumn
                                heading={
                                    <HStack>
                                        <Heading size={"sm"}>Radio Technology</Heading>
                                    </HStack>
                                }
                                grid
                                isLoading={isLoadingHardware}
                            >
                                {hardware.connectionState === "CONNECTED" ? (
                                    <NetworkStateRadioTechnology simCard={simCard} />
                                ) : (
                                    <PlaceholderText text={undefined} />
                                )}
                            </TextColumn>
                            <TextColumn
                                heading={
                                    <HStack>
                                        <Heading size={"sm"}>Cell ID</Heading>
                                    </HStack>
                                }
                                grid
                                isLoading={isLoadingHardware}
                            >
                                {hardware.connectionState === "CONNECTED" ? (
                                    <NetworkStateCellId simCard={simCard} />
                                ) : (
                                    <PlaceholderText text={undefined} />
                                )}
                            </TextColumn>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid
                    templateAreas={`"left-top right"
                                    "left-bottom right"`}
                    templateRows={"1fr 1fr"}
                    templateColumns={"3fr 5fr"}
                    columnGap={4}
                    rowGap={4}
                >
                    <GridItem area={"left-top"}>
                        <Paper header={"Hardware Details"} withDivider>
                            <Grid templateColumns="auto auto" gap="4">
                                <TextColumn
                                    heading={
                                        <HStack>
                                            <Heading size={"sm"}>Name</Heading>
                                            <CopyButton value={hardware?.name || ""} />
                                        </HStack>
                                    }
                                    grid
                                    isLoading={isLoadingHardware}
                                >
                                    <PlaceholderText text={hardware?.name} />
                                </TextColumn>
                                <TextColumn
                                    heading={
                                        <HStack>
                                            <Heading size={"sm"}>Identifier</Heading>
                                            <CopyButton value={hardware?.identifier || ""} />
                                        </HStack>
                                    }
                                    grid
                                    isLoading={isLoadingHardware}
                                >
                                    <PlaceholderText text={hardware?.identifier} />
                                </TextColumn>
                                <TextColumn heading="Manufacturer" grid isLoading={isLoadingHardware}>
                                    <PlaceholderText text={hardware?.manufacturer} />
                                </TextColumn>
                                <TextColumn heading="Product Code" grid isLoading={isLoadingHardware}>
                                    <PlaceholderText text={hardware?.productCode} />
                                </TextColumn>
                                <TextColumn heading="Hardware Revision" grid isLoading={isLoadingHardware}>
                                    <PlaceholderText text={hardware?.hardwareRevision} />
                                </TextColumn>
                            </Grid>
                        </Paper>
                    </GridItem>
                    <GridItem area={"left-bottom"}>
                        <Paper header={"Modem Details"} withDivider>
                            <Grid templateColumns="auto" gap="4">
                                <TextColumn
                                    heading={
                                        <HStack>
                                            <Heading size={"sm"}>IMEI</Heading>
                                            <CopyButton value={hardware?.imei || ""} />
                                        </HStack>
                                    }
                                    grid
                                    isLoading={isLoadingHardware}
                                >
                                    <ImeiInfo imei={hardware?.imei} showCheckDigit as={"text"} />
                                </TextColumn>
                                <TextColumn heading="Modem Firmware" grid isLoading={isLoadingHardware}>
                                    <PlaceholderText text={hardware?.modemFirmware} />
                                </TextColumn>
                                <TextColumn heading="Modem Manufacturer" grid isLoading={isLoadingHardware}>
                                    <PlaceholderText text={hardware?.modemManufacturer} />
                                </TextColumn>
                            </Grid>
                        </Paper>
                    </GridItem>
                    <GridItem area={"right"}>
                        <MapLocationPaper
                            header={"Device Location"}
                            accuracy={1000}
                            isLoading={isLoadingNetworkState}
                            position={toMapPosition(networkState?.cellularPosition)}
                            zoom={13}
                            markerIcon={"cell-tower"}
                            cardProps={{
                                height: "100%",
                            }}
                            state={isMapLocationStale(hardware, networkState) ? "STALE" : "OK"}
                            updatedAt={networkState?.cellularPosition?.recordedAt}
                        />
                    </GridItem>
                </Grid>
            </VStack>
        </TabPanel>
    );
};
