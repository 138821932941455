import { ChevronDownIcon, HasPermission } from "@cobira/ui-library";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import { Permission } from "../../../../utils/Permissions";
import { SimActionType } from "../../../../components/Modal/SimCardActionModals/SimCardActionModals";

export interface SimCardDetailActionMenuProps {
    onClick: (actionType: SimActionType) => void;
}

const SimCardDetailActionMenu = ({ onClick }: SimCardDetailActionMenuProps) => {
    return (
        <HasPermission values={[Permission.action.write]} type={"allOf"}>
            <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    Manage
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={() => onClick("activate")}>Activate</MenuItem>
                    <MenuItem onClick={() => onClick("suspend")}>Suspend</MenuItem>
                    <MenuItem onClick={() => onClick("reset")}>Reset</MenuItem>
                    <MenuItem onClick={() => onClick("assign-usage-package")}>Assign Usage Package</MenuItem>
                    <MenuItem onClick={() => onClick("revoke-usage-package")}>Revoke Usage Package</MenuItem>
                    <MenuItem onClick={() => onClick("set-imei-lock")}>Set IMEI Lock</MenuItem>
                    <MenuItem onClick={() => onClick("imei-lock-state")}>Set IMEI Lock Behaviour</MenuItem>
                    <MenuItem onClick={() => onClick("send-sms")}>Send SMS</MenuItem>
                </MenuList>
            </Menu>
        </HasPermission>
    );
};

export default SimCardDetailActionMenu;
