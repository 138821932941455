import { ImeiLockState } from "../api";

export const ImeiLockStateLabels = {
    [ImeiLockState.Check]: "Validate IMEI on discovery",
    [ImeiLockState.Disabled]: "Skip IMEI checks",
    [ImeiLockState.CheckOrSet]: "Lock to first IMEI",

    getLabel: (value?: ImeiLockState) => {
        return value ? ImeiLockStateLabels[value] : undefined;
    },
};
