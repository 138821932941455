/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NetworkCapability = {
    Supports2G: 'SUPPORTS_2G',
    Supports3G: 'SUPPORTS_3G',
    SupportsLte: 'SUPPORTS_LTE',
    SupportsLteMtc: 'SUPPORTS_LTE_MTC',
    AllowsPermanentRoaming: 'ALLOWS_PERMANENT_ROAMING',
    Unknown: 'UNKNOWN'
} as const;
export type NetworkCapability = typeof NetworkCapability[keyof typeof NetworkCapability];


export function NetworkCapabilityFromJSON(json: any): NetworkCapability {
    return NetworkCapabilityFromJSONTyped(json, false);
}

export function NetworkCapabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkCapability {
    return json as NetworkCapability;
}

export function NetworkCapabilityToJSON(value?: NetworkCapability | null): any {
    return value as any;
}

