import React from "react";
import { BillingGroup } from "../../../api";
import { BillingGroupDataConsumptionChart } from "../../../components/Charts/BillingGroupDataConsumptionChart/BillingGroupDataConsumptionChart";
import { Loading } from "@cobira/ui-library";
import { Box } from "@chakra-ui/react";

export interface BillingGroupDataUsagePaperProps {
    billingGroup?: BillingGroup;
}

const BillingGroupDataUsagePaper = ({ billingGroup }: BillingGroupDataUsagePaperProps) => {
    if (!billingGroup) {
        return <Loading />;
    }
    return (
        <Box w={"100%"} h={"600px"}>
            <BillingGroupDataConsumptionChart billingGroupId={billingGroup?.id} />
        </Box>
    );
};

export default BillingGroupDataUsagePaper;
