import React, { useCallback, useState } from "react";
import {
    HasPermission,
    Page,
    PageTitle,
    SearchBar,
    SearchSingleInput,
    singleInput,
    UserTable,
    useSearch,
} from "@cobira/ui-library";
import { Button, Menu, MenuButton, useBoolean, VStack } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../../../hooks/useApi";
import { User } from "../../../api";
import ConfirmDeleteUserModal from "../../../components/Modal/ConfirmDeleteUserModal/ConfirmDeleteUserModal";
import InviteUserModal from "../../../components/Modal/InviteUserModal/InviteUserModal";
import ConfirmUnblockUserModal from "../../../components/Modal/ConfirmUnblockUserModal/ConfirmUnblockUserModal";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";

const UsersListPage = () => {
    const { userApi } = useApi();
    const queryClient = useQueryClient();
    const search = useSearch({
        config: {
            search: singleInput<string>("Search"),
        },
    });
    const { data: users, isLoading: isLoadingUsers } = useQuery(["users", search.state], () => {
        return userApi.getUsers({
            search: search.state?.search?.value || undefined,
        });
    });

    const [isInvitingUser, { toggle: toggleInvitingUser }] = useBoolean();
    const handleInviteUserFormSubmitted = useCallback(() => {
        queryClient.invalidateQueries(["users"]);
        toggleInvitingUser();
    }, [queryClient, toggleInvitingUser]);

    const [userToDelete, setUserToDelete] = useState<User | undefined>(undefined);
    const [userToUnblock, setUserToUnblock] = useState<User | undefined>(undefined);

    const userRolesResolver = (userId: string) => userApi.getUserRoles({ userId: userId });

    return (
        <Page
            title={<PageTitle title="Users" />}
            pageContext={
                <HasPermission values={[Permission.user.write]} type={"allOf"}>
                    <Menu>
                        <MenuButton as={Button} onClick={toggleInvitingUser}>
                            Invite user
                        </MenuButton>
                    </Menu>
                </HasPermission>
            }
            permissions={{ values: [Permission.user.read], deniedComponent: <RestrictedPage /> }}
        >
            <VStack w={"100%"} gap={2} align={"stretch"}>
                <SearchBar
                    defaultFilterInputId={"search"}
                    useSearch={search}
                    filterInputs={[
                        {
                            id: "search",
                            menuLabel: "Search",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "search" })}
                                    placeholder={"Search by name"}
                                />
                            ),
                        },
                    ]}
                />
                <UserTable
                    users={users}
                    isLoading={isLoadingUsers}
                    onDeleteUser={setUserToDelete}
                    onUnblockUser={setUserToUnblock}
                    rolesResolver={userRolesResolver}
                />
                <InviteUserModal isOpen={isInvitingUser} onClose={handleInviteUserFormSubmitted} />
                <ConfirmDeleteUserModal onClose={() => setUserToDelete(undefined)} user={userToDelete} />
                <ConfirmUnblockUserModal onClose={() => setUserToUnblock(undefined)} user={userToUnblock} />
            </VStack>
        </Page>
    );
};

export default UsersListPage;
