import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { AccessToken, AccessTokenApiRevokeAccessTokenRequest, Integration } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";

export interface ConfirmDeleteAccessTokenModalProps {
    onClose: () => void;
    accessToken?: AccessToken;
    integration: Integration;
}

const ConfirmDeleteAccessTokenModal = ({ onClose, accessToken, integration }: ConfirmDeleteAccessTokenModalProps) => {
    const { accessTokenApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const deleteAccessToken = useMutation<void, Error, AccessTokenApiRevokeAccessTokenRequest>(variables =>
        accessTokenApi.revokeAccessToken(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && accessToken?.id) {
                deleteAccessToken.mutate(
                    { integrationId: integration.id, accessTokenId: accessToken.id || "" },
                    {
                        onSuccess: () => {
                            notifications.success("Access Token revoked");
                            queryClient.invalidateQueries(["integrations", integration?.id, "accesstokens"]);
                        },
                        onError: error => notifications.error(error?.message),
                    },
                );
            }
            onClose();
        },
        [accessToken, deleteAccessToken, integration.id, notifications, onClose, queryClient],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!accessToken}
            title={"Revoke access token"}
            description={
                <Container>
                    <Text>You are about to revoke the access token</Text>
                    <Text as={"b"}>{accessToken?.name}</Text>
                </Container>
            }
        />
    );
};

export default ConfirmDeleteAccessTokenModal;
