import React, { FC } from "react";
import { CobiraBarChartPaper, MetricLabelFormatters, MetricsProvider, newRequest } from "@cobira/ui-library";
import { useMetricsResolver } from "../../../hooks/useMetricsResolver";
import { subMonths } from "date-fns";

interface CustomerDataConsumptionChartProps {
    billingGroupId: string;
}

export const BillingGroupSmsConsumptionChart: FC<CustomerDataConsumptionChartProps> = ({ billingGroupId }) => {
    const metricResolver = useMetricsResolver();
    const thisMonth = new Date();
    const twelveMonthsAgo = subMonths(thisMonth, 12);

    const requests = {
        totalData: newRequest({
            bucket: "MONTHLY",
            dataPoint: "SMS_USAGE",
            group: "BILLING_GROUP",
            groupId: billingGroupId,
            fromDateTime: twelveMonthsAgo,
            toDateTime: thisMonth,
        }),
    };

    return (
        <MetricsProvider requests={requests} resolver={metricResolver}>
            {data => (
                <CobiraBarChartPaper
                    title={"SMS consumption"}
                    chartContext={"Last 12 months"}
                    bars={[{ dataSet: data => data.totalData.value, dataSetName: "SMS count" }]}
                    data={data}
                    chartConfig={{
                        xAxisValueLabel: data => MetricLabelFormatters.yearAndMonth(data.totalData),
                    }}
                />
            )}
        </MetricsProvider>
    );
};
