import React from "react";
import { BasicModal, CopyButton, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { AccessToken, Integration } from "../../../api";
import { Heading, HStack, Text, VStack } from "@chakra-ui/react";

export interface AccessTokenDetailModalProps {
    integration: Integration;
    accessToken?: AccessToken;
    onClose: () => void;
}

const AccessTokenDetailModal = ({ onClose, accessToken, integration }: AccessTokenDetailModalProps) => {
    return (
        <BasicModal isOpen={!!accessToken} onClose={onClose} title={"Access Token details"} modalProps={{ size: "xl" }}>
            <VStack w={"100%"} align={"stretch"}>
                <TextColumn heading={"Name"}>
                    <Text>{accessToken?.name}</Text>
                </TextColumn>
                <TextColumn heading={"Description"}>
                    <PlaceholderText text={accessToken?.description} />
                </TextColumn>
                <Heading size={"md"}>Instructions on use</Heading>
                <Text>
                    The access token is used in the basic auth header of the HTTP request. Simply add the integration id
                    shown below as the username, and the access token secret provided when the token was created to
                    create a valid basic auth header.
                </Text>
                <TextColumn
                    heading={
                        <HStack>
                            <Heading size={"md"}>Integration ID</Heading>
                            <CopyButton value={integration.id} />
                        </HStack>
                    }
                >
                    {integration.id}
                </TextColumn>
            </VStack>
        </BasicModal>
    );
};

export default AccessTokenDetailModal;
