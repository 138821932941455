import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SimCard } from "../../api";
import React from "react";
import { SimCardFormButtonControls } from "./SimCardFormButtonControls";
import { ControlledEditable } from "../../components/ControlledEditable/ControlledEditable";

const SimCardFormSchema = z.object({
    customName: z.string().optional(),
});

export type SimCardFormType = z.infer<typeof SimCardFormSchema>;

export interface SimCardFormProps {
    onSubmit: (form: SimCardFormType) => void;
    simCard?: SimCard;
}

export const SimCardForm = ({ onSubmit, simCard }: SimCardFormProps) => {
    const {
        handleSubmit,
        control,
        formState: { defaultValues },
    } = useForm<SimCardFormType>({
        resolver: zodResolver(SimCardFormSchema),
        defaultValues: {
            customName: simCard?.customName,
        },
    });

    return (
        <form>
            <ControlledEditable
                name={"customName"}
                control={control}
                defaultValue={defaultValues?.customName}
                onSubmit={handleSubmit(onSubmit)}
                controls={<SimCardFormButtonControls />}
            />
        </form>
    );
};
