import React from "react";
import { ComparisonTrendIndicator, DataText, MetricInfoCard, newRequest, useMetadata } from "@cobira/ui-library";
import { faWavePulse } from "@fortawesome/pro-regular-svg-icons";
import { useMetricsResolver } from "../../../../hooks/useMetricsResolver";
import { subMonths } from "date-fns";
import { monthName } from "../../../../utils/dateUtils";

interface DataConsumptionInfoCardProps {}

export const DataConsumptionInfoCard: React.FC<DataConsumptionInfoCardProps> = () => {
    const metadata = useMetadata();
    const metricsResolver = useMetricsResolver();

    const oneMonthAgo = subMonths(new Date(), 1);
    const oneMonthAgoName = monthName(oneMonthAgo);
    const twoMonthsAgo = subMonths(oneMonthAgo, 1);
    const twoMonthsAgoName = monthName(twoMonthsAgo);

    return (
        <MetricInfoCard
            comparableRequest={{
                request: newRequest({
                    bucket: "MONTHLY",
                    dataPoint: "DATA_USAGE",
                    group: "TENANT",
                    groupId: metadata.tenantId,
                    fromDateTime: oneMonthAgo,
                    toDateTime: oneMonthAgo,
                }),
                comparisonRequest: newRequest({
                    bucket: "MONTHLY",
                    dataPoint: "DATA_USAGE",
                    group: "TENANT",
                    groupId: metadata.tenantId,
                    fromDateTime: twoMonthsAgo,
                    toDateTime: twoMonthsAgo,
                }),
                cardProps: (metric, compareMetric) => ({
                    value: <DataText bytes={metric?.value || 0} fontSize={"2xl"} color={"header"} fontWeight="bold" />,
                    subtitle: (
                        <ComparisonTrendIndicator
                            oldValue={compareMetric?.value || 0}
                            newValue={metric?.value || 0}
                            suffixText={`since ${twoMonthsAgoName}`}
                        />
                    ),
                }),
            }}
            resolver={metricsResolver}
            title={`Data used in ${oneMonthAgoName}`}
            icon={faWavePulse}
        />
    );
};
