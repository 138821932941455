import { Month } from "@cobira/ui-library";
import { addMonths, subMonths } from "date-fns";

export const monthName = (date: Date) => date.toLocaleDateString("en-US", { month: "long" });

// TODO This should be moved the UI Library as a utility function.
const DateUtils = {
    yearMonthInterval: function (from: Date, to: Date) {
        return {
            from: {
                year: from.getUTCFullYear(),
                month: Object.values(Month)[from.getUTCMonth()],
            },
            to: {
                year: to.getUTCFullYear(),
                month: Object.values(Month)[to.getUTCMonth()],
            },
        };
    },
    currentMonthInterval: () => {
        const from = new Date();
        const to = addMonths(from, 1);
        return DateUtils.yearMonthInterval(from, to);
    },
    thisYearInterval: () => {
        const now = new Date();
        return {
            from: {
                year: now.getUTCFullYear(),
                month: Month.JANUARY,
            },
            to: {
                year: now.getUTCFullYear(),
                month: Month.DECEMBER,
            },
        };
    },
    lastMonthInterval: () => {
        const now = new Date();
        const from = subMonths(now, 1);
        return DateUtils.yearMonthInterval(from, now);
    },
    monthsAgoInterval: (monthsBack: number) => {
        const now = new Date();
        const to = subMonths(now, monthsBack);
        const from = subMonths(to, 1);
        return DateUtils.yearMonthInterval(from, to);
    },
};

export default DateUtils;
