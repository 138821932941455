import React, { ReactNode } from "react";
import { SimCard } from "../../../api";
import { useApi } from "../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { PlaceholderText } from "@cobira/ui-library";

interface NetworkStateRadioTechnologyProps {
    simCard?: SimCard;
    loadingComponent?: ReactNode;
    placeholderComponent?: ReactNode;
}

export const NetworkStateRadioTechnology = ({
    simCard,
    loadingComponent,
    placeholderComponent,
}: NetworkStateRadioTechnologyProps) => {
    const { simCardApi } = useApi();
    const { data: networkState, isLoading: isLoadingNetworkState } = useQuery(
        ["simcards", simCard?.icc, "networkstate"],
        () => simCardApi.getSimCardNetworkState({ icc: simCard?.icc || "" }),
        { enabled: simCard?.icc !== undefined },
    );

    if (isLoadingNetworkState) {
        return <>{loadingComponent}</>;
    }

    if (!isLoadingNetworkState && !networkState) {
        return <>{placeholderComponent}</>;
    }

    return <PlaceholderText text={networkState?.cell?.radioTechnology} />;
};
