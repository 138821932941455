import { AsyncActorResolvers } from "@cobira/ui-library";
import { useApi } from "./useApi";

const useActionActor = (): AsyncActorResolvers => {
    const { userApi, integrationApi } = useApi();

    return {
        USER: (actionId, actionActor) => ({
            queryKey: [actionId, actionActor.type, actionActor.id],
            queryFn: () =>
                userApi
                    .getUserDetails({ userId: actionActor.id })
                    .then(value => `${value.name} (${value.email})`)
                    .catch(() => actionActor.id),
        }),
        APPLICATION: (actionId, actionActor) => ({
            queryKey: [actionId, actionActor.type, actionActor.id],
            queryFn: () =>
                actionActor.parentId
                    ? integrationApi
                          .getAccessToken({
                              integrationId: actionActor.parentId,
                              accessTokenId: actionActor.id,
                          })
                          .then(value => `${value.name}`)
                          .catch(() => actionActor.id)
                    : actionActor.id,
        }),
        PATCH: (actionId, actionActor) => ({
            queryKey: [actionId, actionActor.type, actionActor.id],
            queryFn: () => actionActor.id,
        }),
        IMEI_LOCK: (actionId, actionActor) => ({
            queryKey: [actionId, actionActor.type, actionActor.id],
            queryFn: () => actionActor.id,
        }),
        UNKNOWN: (actionId, actionActor) => ({
            queryKey: [actionId, actionActor.type, actionActor.id],
            queryFn: () => actionActor.id,
        }),
    };
};

export default useActionActor;
