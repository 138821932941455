import React, { ReactElement } from "react";
import { PlaceholderText, QueryCell } from "@cobira/ui-library";
import { Spinner } from "@chakra-ui/react";
import { UsagePackageType } from "../../../api";
import { useApi } from "../../../hooks/useApi";

export interface UsagePackageTypeCellProps {
    usagePackageTypeId?: string;
    property: (usagePackageType?: UsagePackageType) => ReactElement;
}

export const UsagePackageTypeCell = ({ usagePackageTypeId, property }: UsagePackageTypeCellProps) => {
    const { usagePackageApi } = useApi();
    if (!usagePackageTypeId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<Spinner />}
            query={{
                queryFn: () => usagePackageApi.getUsagePackageType({ usagePackageTypeId: usagePackageTypeId }),
                queryKey: ["usagepackagetypes", usagePackageTypeId],
            }}
            render={result => property(result?.data)}
        />
    );
};
