/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataUsageByPlmn } from './DataUsageByPlmn';
import {
    DataUsageByPlmnFromJSON,
    DataUsageByPlmnFromJSONTyped,
    DataUsageByPlmnToJSON,
} from './DataUsageByPlmn';

/**
 * Contains an array of networks which this IMSI has had activity on, together with information about consumption.
 * @export
 * @interface SimCardDataUsageDataUsageInnerImsisInner
 */
export interface SimCardDataUsageDataUsageInnerImsisInner {
    /**
     * 
     * @type {string}
     * @memberof SimCardDataUsageDataUsageInnerImsisInner
     */
    imsi: string;
    /**
     * 
     * @type {Array<DataUsageByPlmn>}
     * @memberof SimCardDataUsageDataUsageInnerImsisInner
     */
    plmns?: Array<DataUsageByPlmn>;
}

/**
 * Check if a given object implements the SimCardDataUsageDataUsageInnerImsisInner interface.
 */
export function instanceOfSimCardDataUsageDataUsageInnerImsisInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "imsi" in value;

    return isInstance;
}

export function SimCardDataUsageDataUsageInnerImsisInnerFromJSON(json: any): SimCardDataUsageDataUsageInnerImsisInner {
    return SimCardDataUsageDataUsageInnerImsisInnerFromJSONTyped(json, false);
}

export function SimCardDataUsageDataUsageInnerImsisInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardDataUsageDataUsageInnerImsisInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imsi': json['imsi'],
        'plmns': !exists(json, 'plmns') ? undefined : ((json['plmns'] as Array<any>).map(DataUsageByPlmnFromJSON)),
    };
}

export function SimCardDataUsageDataUsageInnerImsisInnerToJSON(value?: SimCardDataUsageDataUsageInnerImsisInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imsi': value.imsi,
        'plmns': value.plmns === undefined ? undefined : ((value.plmns as Array<any>).map(DataUsageByPlmnToJSON)),
    };
}

