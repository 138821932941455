import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup, Input } from "@chakra-ui/react";
import { ImeiLockUpdateBehaviour } from "../../api";
import { ImeiLockUpdateBehaviourLabels } from "../../labels/ImeiLockUpdateBehaviourLabels";

const ImeiLockFormSchema = z.object({
    imei: z
        .string()
        .min(1, "The IMEI number is required")
        .min(14, "IMEI Number must be at least 14 digits")
        .max(16, "IMEI cannot exceed 16 digits"),
    operation: z.nativeEnum(ImeiLockUpdateBehaviour),
});

export type ImeiLockFormType = z.infer<typeof ImeiLockFormSchema>;

export interface ImeiLockFormProps {
    onSubmit: (form: ImeiLockFormType) => void;
    onCancel?: () => void;
}

const ImeiLockForm = ({ onSubmit, onCancel }: ImeiLockFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
    } = useForm<ImeiLockFormType>({
        resolver: zodResolver(ImeiLockFormSchema),
        defaultValues: {
            operation: ImeiLockUpdateBehaviour.Create,
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Operation"} error={errors?.operation}>
                    <ControlledAutoCompleteSingleSelectInput
                        input={{
                            items: Object.values(ImeiLockUpdateBehaviour),
                            displayMapping: value => ImeiLockUpdateBehaviourLabels[value],
                            autocompleteAbleMapping: value => ImeiLockUpdateBehaviourLabels[value],
                            placeholder: "Choose operation",
                        }}
                        control={{
                            name: "operation",
                            control: control,
                        }}
                    />
                </FormField>
                <FormField label={"IMEI"} error={errors?.imei}>
                    <Input {...register("imei")} />
                </FormField>
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Confirm
                </Button>
                {oncancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default ImeiLockForm;
