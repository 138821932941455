import React, { useCallback } from "react";
import { ConfirmModal, HasPermission, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { User, UserApiCreateUserRequest } from "../../../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";
import { Permission } from "../../../utils/Permissions";

export interface ConfirmUnblockUserModalProps {
    onClose: () => void;
    user?: User;
}

const ConfirmUnblockUserModal = ({ onClose, user }: ConfirmUnblockUserModalProps) => {
    const { userApi, roleApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const { data: managerRole } = useQuery(["roles", "manager"], () =>
        roleApi.getRoles().then(roles => roles.find(role => role.name.toLowerCase() === "manager")),
    );

    const createUser = useMutation<User, Error, UserApiCreateUserRequest>(variables => userApi.createUser(variables), {
        onSuccess: user => {
            queryClient.invalidateQueries(["users"]);
            notifications.success(`User ${user.email} has been unblocked and re-invited`);
        },
    });

    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && user?.id) {
                createUser.mutate({
                    createUser: {
                        name: user.name,
                        email: user.email,
                        roles: [managerRole?.id || ""],
                    },
                });
            }
            onClose();
        },
        [user, onClose, createUser, managerRole?.id],
    );

    return (
        <HasPermission values={[Permission.user.write]} type={"allOf"}>
            <ConfirmModal
                onClose={handleOnClose}
                isOpen={!!user}
                title={"Unblock User"}
                description={
                    <Container>
                        <Text>Are you sure you want to unblock the user</Text>
                        <Text as={"b"}>
                            {user?.name} ({user?.email})
                        </Text>
                    </Container>
                }
            />
        </HasPermission>
    );
};

export default ConfirmUnblockUserModal;
