import React, { ReactElement } from "react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../../hooks/useApi";
import { SimCardNetworkState } from "../../../../api";

export interface SimNetworkStateCellProps {
    icc?: string;
    property: (networkState?: SimCardNetworkState) => ReactElement;
}

export const SimNetworkStateCell = ({ icc, property }: SimNetworkStateCellProps) => {
    const { simCardApi } = useApi();
    if (!icc) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () => simCardApi.getSimCardNetworkState({ icc: icc }),
                queryKey: ["sim-network-state", icc],
            }}
            render={result => property(result?.data)}
        />
    );
};
