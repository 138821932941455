import { useApi } from "../../../hooks/useApi";
import {
    Action,
    ActionCompletedNotification,
    ActionFailedNotification,
    BasicModal,
    useActionNotification,
} from "@cobira/ui-library";
import { SimCardApiUpdateImeiLocksRequest } from "../../../api";
import { useMutation } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import ImeiLockForm, { ImeiLockFormType } from "../../../forms/ImeiLockForm/ImeiLockForm";
import SimCardActionInProgressNotification from "../../ActionNotification/SimCardActionInProgressNotification/SimCardActionInProgressNotification";

export interface SetImeiLockModalProps {
    icc: string;
    isOpen: boolean;
    onClose: () => void;
}

const SetImeiLockModal = ({ isOpen, icc, onClose }: SetImeiLockModalProps) => {
    const { simCardApi, actionApi } = useApi();
    const { trackAction } = useActionNotification({});

    const setImeiLockStates = useMutation<Action[], Error, SimCardApiUpdateImeiLocksRequest>(
        variables => simCardApi.updateImeiLocks(variables),
        {
            onSuccess: data => {
                data.forEach(action => {
                    trackAction({
                        action: action,
                        fetchAction: actionId => actionApi.getAction({ actionId: actionId }),
                        options: {
                            successMessage: successMessage,
                            errorMessage: errorMessage,
                            loadingMessage: loadingMessage(action),
                        },
                    });
                });
            },
        },
    );

    const handleSubmit = useCallback(
        (form: ImeiLockFormType) => {
            setImeiLockStates.mutate({
                updateImeiLockEntry: [
                    {
                        icc: icc,
                        imei: form.imei,
                        updateBehaviour: form.operation,
                    },
                ],
            });
            onClose();
        },
        [setImeiLockStates, icc, onClose],
    );

    const successMessage = useCallback(
        (data: Action) => {
            return (
                <ActionCompletedNotification
                    action={data}
                    successTitle={"Allowed IMEIs updated"}
                    successMessage={`Allowed IMEIs updated for ${icc}`}
                    failureComponent={<ActionFailedNotification title={"Failed to update allowed IMEIs"} />}
                />
            );
        },
        [icc],
    );
    const errorMessage = useCallback(() => <ActionFailedNotification title={"Failed to update allowed IMEIs"} />, []);
    const loadingMessage = useCallback(
        (action: Action) => (
            <SimCardActionInProgressNotification
                actionApi={actionApi}
                actionId={action.id}
                title={"Updating Allowed IMEIs"}
                message={() => null}
            />
        ),
        [actionApi],
    );

    return (
        <BasicModal title={`Update IMEI Lock`} onClose={onClose} isOpen={isOpen}>
            <VStack w={"100%"} align={"stretch"}>
                <ImeiLockForm onSubmit={handleSubmit} onCancel={onClose} />
            </VStack>
        </BasicModal>
    );
};

export default SetImeiLockModal;
