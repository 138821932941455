/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A  specific month represented as an ENUM.
 * @export
 */
export const Month = {
    January: 'JANUARY',
    February: 'FEBRUARY',
    March: 'MARCH',
    April: 'APRIL',
    May: 'MAY',
    June: 'JUNE',
    July: 'JULY',
    August: 'AUGUST',
    September: 'SEPTEMBER',
    October: 'OCTOBER',
    November: 'NOVEMBER',
    December: 'DECEMBER'
} as const;
export type Month = typeof Month[keyof typeof Month];


export function MonthFromJSON(json: any): Month {
    return MonthFromJSONTyped(json, false);
}

export function MonthFromJSONTyped(json: any, ignoreDiscriminator: boolean): Month {
    return json as Month;
}

export function MonthToJSON(value?: Month | null): any {
    return value as any;
}

