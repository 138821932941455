/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Optional capabilities that may be tied to SIM Cards with certain SIM Configurations.
 * @export
 */
export const SimCardCapabilityName = {
    CellularLocationReport: 'CELLULAR_LOCATION_REPORT',
    Ping: 'PING',
    PrivateApn: 'PRIVATE_APN',
    PublicApn: 'PUBLIC_APN',
    HardwareRegistration: 'HARDWARE_REGISTRATION',
    SignalConditionsReport: 'SIGNAL_CONDITIONS_REPORT'
} as const;
export type SimCardCapabilityName = typeof SimCardCapabilityName[keyof typeof SimCardCapabilityName];


export function SimCardCapabilityNameFromJSON(json: any): SimCardCapabilityName {
    return SimCardCapabilityNameFromJSONTyped(json, false);
}

export function SimCardCapabilityNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardCapabilityName {
    return json as SimCardCapabilityName;
}

export function SimCardCapabilityNameToJSON(value?: SimCardCapabilityName | null): any {
    return value as any;
}

