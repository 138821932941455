import React from "react";
import { Integration } from "../../../api";
import { Code, Heading, Kbd, ListItem, TabPanel, Text, UnorderedList, VStack } from "@chakra-ui/react";
import { Paper, SecretField, TextField } from "@cobira/ui-library";

export interface IntegrationDetailsPanelProps {
    integration?: Integration;
}

const IntegrationDetailsPanel = ({ integration }: IntegrationDetailsPanelProps) => {
    return (
        <TabPanel>
            <VStack w={"100%"} align={"stretch"} gap={4}>
                <Paper header={"Webhook Verification"}>
                    <Heading size={"md"}>Shared Secret</Heading>
                    <SecretField secret={integration?.sharedSecret} w={"30rem"} mt={2} />
                    <br />
                    <Text>
                        The signature is added to the HTTP headers using the following key:{" "}
                        <Code>X-Webhook-Signature</Code>, the value is a composite value containing the timestamp
                        denoted with <Code>t=</Code> and the signed checksum in base64 <Code>s=</Code>.
                        <br />
                        The values are separated by the character <Kbd>;</Kbd>.
                        <br />
                        <br />
                        <b>An example of such a signature is shown below</b>
                        <br />
                        <Code>t=1631033906727;s=eZWuiqx+9q9rIVMrpenZPtDfAnA6z9NT+r+2NbCbNFg=</Code>.
                        <br />
                        <br />
                        The checksum part will also include the value from <Code>X-Webhook-Id</Code>.
                        <br />
                        Parsing the signature and extracting the components is pretty straightforward.
                        <br />
                        Split the signature by <Kbd>;</Kbd> Then subtract the two first characters of each part{" "}
                        <Code>t=</Code> for the timestamp part and <Code>s=</Code> for the signature part. The result
                        should then be the two values:
                    </Text>
                    <UnorderedList>
                        <ListItem>
                            <Code>timestamp = 1631033906727</Code>
                        </ListItem>
                        <ListItem>
                            <Code>signature = eZWuiqx+9q9rIVMrpenZPtDfAnA6z9NT+r+2NbCbNFg=</Code>
                        </ListItem>
                    </UnorderedList>
                    <br />
                    <Text>
                        Then extract the <Code>X-Webhook-Id</Code> from the HTTP headers. Now you are ready to validate
                        the webhook.
                        <br />
                        <br />
                        <b>Validating the freshness of the webhook</b>
                        <br />
                        The maximum age of the webhook can be checked with the timestamp using the current time as the
                        comparison. The timestamp is always provided in UNIX epoch milliseconds.
                        <br />
                        If a webhook arrives where the timestamp <Code>t</Code> is older than your chosen duration, you
                        should discard the webhook as being stale.
                        <br />
                        <br />
                        <b>Checking authenticity of the webhook</b>
                        <br />
                        The signature that is included in the HTTP headers, must match the one that you calculate on the
                        receiving end, otherwise the webhook is not sent by us and you should discard it. The validation
                        is performed using the following &quot;simplified&quot; algorithm.
                        <br />
                        <Code>
                            let payload = timestamp.webhookId.webhookPayload
                            <br />
                            base64(sha256(sharedSecret, bytes(payload, UTF8))) == signature
                        </Code>
                        <br />
                        If this statement is true, then the payload has not been modified, and the webhook originated
                        from the us and the checksum was computed using the shared secret found on this page.
                    </Text>
                </Paper>
                <Paper header={"Access Token Instructions"}>
                    <Heading size={"md"}>Username</Heading>
                    <TextField value={integration?.id} withCopy maxW={"30rem"} />
                    <br />
                    Using access tokens with the API just requires adding the credentials in the&nbsp;
                    <Code>Authorization</Code> header of the HTTP request.
                    <br />
                    The basic auth credentials is created using the username above, and the access token secret.
                    <br />
                    Access token secrets can only be retrieved when the access token is created, if lost, then you will
                    have to create a new access token.
                </Paper>
                <Paper header={"Webhook Schemas"}>
                    All webhooks that can be subscribed to, are defined in JSON schemas. These schemas can be downloaded
                    from this page under the <b>Manage</b> menu.
                    <br />
                    To consume these webhook schemas, we suggest using one of the many available code generators for
                    various target languages.
                    <br />
                    Bear in mind that the chosen generator must support file references as the downloaded schemas will
                    include schemas from sub folders.
                </Paper>
            </VStack>
        </TabPanel>
    );
};

export default IntegrationDetailsPanel;
