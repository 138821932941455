/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Representation of an integration configuration. 
 * The shared secret is used to compute the signature for all emitted webhooks.
 * @export
 * @interface Integration
 */
export interface Integration {
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    sharedSecret: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    description?: string | null;
}

/**
 * Check if a given object implements the Integration interface.
 */
export function instanceOfIntegration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sharedSecret" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function IntegrationFromJSON(json: any): Integration {
    return IntegrationFromJSONTyped(json, false);
}

export function IntegrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Integration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sharedSecret': json['sharedSecret'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function IntegrationToJSON(value?: Integration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sharedSecret': value.sharedSecret,
        'name': value.name,
        'description': value.description,
    };
}

