import React, { useCallback } from "react";
import { ConfirmModal, HasPermission, InfoBox, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { Integration, IntegrationApiRollIntegrationSecretRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Text } from "@chakra-ui/react";
import { Permission } from "../../../utils/Permissions";

export interface ConfirmRollIntegrationModalProps {
    isOpen: boolean;
    onClose: () => void;
    integration?: Integration;
}

const ConfirmRollIntegrationSecretModal = ({ isOpen, onClose, integration }: ConfirmRollIntegrationModalProps) => {
    const { integrationApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const rollSecret = useMutation<Integration, Error, IntegrationApiRollIntegrationSecretRequest>(variables =>
        integrationApi.rollIntegrationSecret(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && integration?.id) {
                rollSecret.mutate(
                    { integrationId: integration.id || "" },
                    {
                        onSuccess: () => {
                            notifications.success("Secret has been rolled");
                            queryClient.invalidateQueries(["integrations", integration?.id]);
                        },
                        onError: error => notifications.error(error?.message),
                    },
                );
            }
            onClose();
        },
        [integration, notifications, onClose, queryClient, rollSecret],
    );

    return (
        <HasPermission values={[Permission.integration.write]} type={"allOf"}>
            <ConfirmModal
                onClose={handleOnClose}
                isOpen={isOpen}
                title={"Roll Integration Secret"}
                description={
                    <InfoBox
                        status={"warning"}
                        withIcon
                        description={
                            <Text>
                                This action rolls the integration shared secret used to compute the signature for
                                emitted webhooks. Webhooks that were already scheduled for delivery will continue to use
                                the old secret until successfully delivered, or they failed.
                            </Text>
                        }
                    />
                }
            />
        </HasPermission>
    );
};

export default ConfirmRollIntegrationSecretModal;
