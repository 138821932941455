import React from "react";
import {
    Auth0UserAvatar,
    Authentication,
    CobiraBrandTitle,
    MainLayout,
    SidebarHeader,
    SidebarNew,
    useCobiraEnvironment,
} from "@cobira/ui-library";
import { Auth0Provider } from "@auth0/auth0-react";
import SimCardListPage from "./pages/simcard/SimCardListPage/SimCardListPage";
import UsagePackageTypeListPage from "./pages/usagepackagetypes/UsagePackageTypeListPage/UsagePackageTypeListPage";
import UsersListPage from "./pages/users/UsersListPage/UsersListPage";
import { Navigate, Route, Routes } from "react-router";
import SimCardDetailPage from "./pages/simcard/SimCardDetailPage/SimCardDetailPage";
import IntegrationPage from "./pages/integration/IntegrationPage/IntegrationPage";
import BillingGroupListPage from "./pages/billinggroups/BillingGroupListPage/BillingGroupListPage";
import BillingGroupDetailPage from "./pages/billinggroups/BillingGroupDetailPage/BillingGroupDetailPage";
import CmpActionListPage from "./pages/action/CmpActionListPage/CmpActionListPage";
import CmpActionDetailPage from "./pages/action/CmpActionDetailsPage/CmpActionDetailPage";
import UsagePackageTypeDetailPage from "./pages/usagepackagetypes/UsagePackageTypeDetailPage/UsagePackageTypeDetailPage";
import SidebarItems from "./components/SidebarItems/SidebarItems";
import DashboardsPage from "./pages/dasbhoard/DashboardsPage/DashboardsPage";

function App() {
    const [, environment] = useCobiraEnvironment();

    const SideBar = (
        <SidebarNew
            header={<SidebarHeader logoSrc={"/branding/logo.svg"} />}
            userAvatar={<Auth0UserAvatar />}
            items={SidebarItems}
        />
    );

    return (
        <>
            <CobiraBrandTitle />
            <Auth0Provider
                domain={environment.auth.domain}
                clientId={environment.auth.clientId}
                cacheLocation={"localstorage"}
                useRefreshTokens={true}
                authorizationParams={{
                    audience: environment.auth.audience,
                    redirect_uri: environment.auth.redirectUrl,
                    scope: "openid email profile offline_access",
                }}
            >
                <Authentication>
                    <MainLayout sidebar={SideBar}>
                        <Routes>
                            <Route path="/simcards/:icc" element={<SimCardDetailPage />} />
                            <Route path="/simcards" element={<SimCardListPage />} />
                            <Route path="/actions" element={<CmpActionListPage />} />
                            <Route path="/actions/:actionId" element={<CmpActionDetailPage />} />
                            <Route path="/integration" element={<IntegrationPage />} />
                            <Route path="/users" element={<UsersListPage />} />
                            <Route path="/" element={<Navigate to="/simcards" />} />
                            <Route path="/billing-groups" element={<BillingGroupListPage />} />
                            <Route path="/billing-groups/:billingGroupId" element={<BillingGroupDetailPage />} />
                            <Route path="/usage-package-types" element={<UsagePackageTypeListPage />} />
                            <Route
                                path="/usage-package-types/:usagePackageTypeId"
                                element={<UsagePackageTypeDetailPage />}
                            />
                            <Route path="/dashboards/account" element={<DashboardsPage />} />
                        </Routes>
                    </MainLayout>
                </Authentication>
            </Auth0Provider>
        </>
    );
}

export default App;
