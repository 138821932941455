import React from "react";
import {
    CobiraTable,
    DataPackageAllowance,
    Duration,
    Page,
    PageTitle,
    PlaceholderText,
    SmsPackageAllowance,
    usePageNavigation,
} from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { UsagePackageType } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { dataAllowance, smsAllowance } from "../../../utils/usagePackageTypeUtils";
import DeletedAt from "../../../components/DeletedAt/DeletedAt";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";

const usagePackageTypeColumn = createColumnHelper<UsagePackageType>();
const COLUMNS = [
    usagePackageTypeColumn.accessor("customName", {
        id: "usagePackageTypeName",
        header: "Name",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    usagePackageTypeColumn.accessor("description", {
        id: "usagePackageTypeDescription",
        header: "Description",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    usagePackageTypeColumn.accessor("usageAllowances", {
        id: "usagePackageTypeDataAllowance",
        header: "Data Allowance",
        cell: row => {
            const allowance = dataAllowance(row.getValue());
            return (
                <DataPackageAllowance
                    allowance={allowance?.allowanceAmount || undefined}
                    enabled={allowance?.enabled}
                />
            );
        },
    }),
    usagePackageTypeColumn.accessor("usageAllowances", {
        id: "usagePackageTypeSmsAllowance",
        header: "SMS Allowance",
        cell: row => {
            const allowance = smsAllowance(row.getValue());
            return (
                <SmsPackageAllowance allowance={allowance?.allowanceAmount || undefined} enabled={allowance?.enabled} />
            );
        },
    }),
    usagePackageTypeColumn.accessor("packageDuration", {
        id: "usagePackageTypeDuration",
        header: "Duration",
        cell: row => <Duration value={row.getValue()} />,
    }),
    usagePackageTypeColumn.accessor("deletedAt", {
        id: "deletedAt",
        header: "Deleted At",
        cell: row => <DeletedAt deletedAt={row.getValue()} />,
    }),
];

const UsagePackageTypeListPage = () => {
    const { usagePackageApi } = useApi();
    const { data: usagePackageTypes } = useQuery(["usagepackagetypes"], () => {
        return usagePackageApi.getAvailableUsagePackageTypes();
    });

    const { navigate } = usePageNavigation<UsagePackageType>({ route: value => `/usage-package-types/${value.id}` });

    return (
        <Page
            title={<PageTitle title="Usage Package Types" />}
            permissions={{ values: [Permission.usagePackage.read], deniedComponent: <RestrictedPage /> }}
        >
            <CobiraTable
                columns={COLUMNS}
                data={usagePackageTypes || []}
                withRowClick={{ enableHoverStyle: true, onRowClicked: navigate }}
                withPagination
            />
        </Page>
    );
};

export default UsagePackageTypeListPage;
