import React, { useCallback, useMemo } from "react";
import {
    AddIcon,
    ChevronDownIcon,
    CobiraTabs,
    DownloadIcon,
    ErrorPage,
    FaIcon,
    HasPermission,
    Page,
    PageTitle,
} from "@cobira/ui-library";
import { Button, Menu, MenuButton, MenuItem, MenuList, useBoolean } from "@chakra-ui/react";
import { useApi } from "../../../hooks/useApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import WebhooksPanel from "./WebhooksPanel";
import AccessTokensPanel from "./AccessTokensPanel";
import CreateWebhookModal from "../../../components/Modal/CreateWebhookModal/CreateWebhookModal";
import CreateAccessTokenModal from "../../../components/Modal/CreateAccessTokenModal/CreateAccessTokenModal";
import ConfirmRollIntegrationSecretModal from "../../../components/Modal/ConfirmRollIntegrationSecretModal/ConfirmRollIntegrationSecretModal";
import IntegrationDetailPanel from "./IntegrationDetailPanel";
import { useDownloadFile } from "../../../hooks/useDownloadFile";
import ApiDocumentationPanel from "./ApiDocumentationPanel";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";

const IntegrationPage = () => {
    const { integrationApi, documentationApi } = useApi();
    const queryClient = useQueryClient();
    const [isCreatingWebhook, { toggle: toggleCreatingWebhook }] = useBoolean();
    const [isCreatingAccessToken, { toggle: toggleCreatingAccessToken }] = useBoolean();
    const [isRollingSecret, { toggle: toggleRollingSecret }] = useBoolean();
    const { data: integrations, isLoading } = useQuery(["integrations"], () => integrationApi.getIntegrations());
    const integration = useMemo(() => integrations?.at(0), [integrations]);
    const { ref, url, download, fileName } = useDownloadFile({
        downloadFunction: () => documentationApi.downloadWebhookSchemas(),
        fileName: "webhook-schemas.zip",
    });
    const {
        ref: apiSpecRef,
        url: apiSpecUrl,
        download: downloadApiSpec,
        fileName: apiSpecFileName,
    } = useDownloadFile({
        downloadFunction: () => documentationApi.downloadApiDocumentation(),
        fileName: "cmp-api-documentation.yaml",
    });

    const handleWebhookCreationSubmitted = useCallback(
        (created: boolean) => {
            if (created) {
                queryClient.invalidateQueries(["integrations", integration?.id, "webhooks"]);
            }
            toggleCreatingWebhook();
        },
        [integration, queryClient, toggleCreatingWebhook],
    );

    const handleAccessTokenCreationSubmitted = useCallback(
        (created: boolean) => {
            if (created) {
                queryClient.invalidateQueries(["integrations", integration?.id, "accesstokens"]);
            }
            toggleCreatingAccessToken();
        },
        [integration, queryClient, toggleCreatingAccessToken],
    );

    if (!integration) {
        if (isLoading) {
            return <></>;
        }
        return (
            <ErrorPage
                code={"404"}
                title={"No Integrations"}
                description={"Please contact support about missing integration details"}
                escapeDescription={"Return back to the landing page"}
                escapePath={"/"}
            />
        );
    }

    return (
        <>
            <a href={url} download={fileName} hidden ref={ref} />
            <a href={apiSpecUrl} download={apiSpecFileName} hidden ref={apiSpecRef} />
            <Page
                title={<PageTitle title={"Integration Details"} />}
                permissions={{ values: [Permission.integration.read], deniedComponent: <RestrictedPage /> }}
                pageContext={
                    <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                            Manage
                        </MenuButton>
                        <MenuList>
                            <HasPermission values={[Permission.webhook.write]} type={"allOf"}>
                                <MenuItem icon={<AddIcon />} onClick={toggleCreatingWebhook}>
                                    Create Webhook
                                </MenuItem>
                            </HasPermission>
                            <HasPermission values={[Permission.accessToken.write]} type={"allOf"}>
                                <MenuItem icon={<AddIcon />} onClick={toggleCreatingAccessToken}>
                                    Create Access Token
                                </MenuItem>
                            </HasPermission>
                            <HasPermission values={[Permission.integration.write]} type={"allOf"}>
                                <MenuItem icon={<FaIcon icon={faArrowsRotate} />} onClick={toggleRollingSecret}>
                                    Roll Secret
                                </MenuItem>
                            </HasPermission>
                            <MenuItem icon={<DownloadIcon />} onClick={download}>
                                Download Webhook Schemas
                            </MenuItem>
                            <MenuItem icon={<DownloadIcon />} onClick={downloadApiSpec}>
                                Download API Documentation
                            </MenuItem>
                        </MenuList>
                    </Menu>
                }
            >
                <CobiraTabs
                    tabs={[
                        {
                            title: "Info",
                            content: <IntegrationDetailPanel integration={integration} />,
                        },
                        {
                            title: "Webhooks",
                            content: <WebhooksPanel integration={integration} />,
                            permissions: { values: [Permission.webhook.read] },
                        },
                        {
                            title: "Access Tokens",
                            content: <AccessTokensPanel integration={integration} />,
                            permissions: { values: [Permission.accessToken.read] },
                        },
                        {
                            title: "API Documentation",
                            content: <ApiDocumentationPanel />,
                        },
                    ]}
                />

                <ConfirmRollIntegrationSecretModal
                    isOpen={isRollingSecret}
                    onClose={toggleRollingSecret}
                    integration={integration}
                />
                <CreateWebhookModal isOpen={isCreatingWebhook} onClose={handleWebhookCreationSubmitted} />
                <CreateAccessTokenModal
                    isOpen={isCreatingAccessToken}
                    onClose={handleAccessTokenCreationSubmitted}
                    integration={integration}
                />
            </Page>
        </>
    );
};

export default IntegrationPage;
