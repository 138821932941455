/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Available webhook subscription types as an enum.
 * @export
 */
export const WebhookSubscriptionType = {
    MobileOriginatedSms: 'MOBILE_ORIGINATED_SMS',
    Unknown: 'UNKNOWN',
    SimCardIpAddressUpdated: 'SIM_CARD_IP_ADDRESS_UPDATED',
    SimCardActiveImsiUpdated: 'SIM_CARD_ACTIVE_IMSI_UPDATED',
    SimCardCellularPositionUpdated: 'SIM_CARD_CELLULAR_POSITION_UPDATED',
    SimCardCellUpdated: 'SIM_CARD_CELL_UPDATED',
    SimCardSignalConditionsUpdated: 'SIM_CARD_SIGNAL_CONDITIONS_UPDATED',
    SimCardImeiUpdated: 'SIM_CARD_IMEI_UPDATED',
    ImeiLockViolated: 'IMEI_LOCK_VIOLATED',
    UsagePackageUpdated: 'USAGE_PACKAGE_UPDATED',
    UsagePackageTypeUpdated: 'USAGE_PACKAGE_TYPE_UPDATED',
    ActionUpdated: 'ACTION_UPDATED',
    ActionItemUpdated: 'ACTION_ITEM_UPDATED'
} as const;
export type WebhookSubscriptionType = typeof WebhookSubscriptionType[keyof typeof WebhookSubscriptionType];


export function WebhookSubscriptionTypeFromJSON(json: any): WebhookSubscriptionType {
    return WebhookSubscriptionTypeFromJSONTyped(json, false);
}

export function WebhookSubscriptionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebhookSubscriptionType {
    return json as WebhookSubscriptionType;
}

export function WebhookSubscriptionTypeToJSON(value?: WebhookSubscriptionType | null): any {
    return value as any;
}

