import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    ControlledAutoCompleteSingleSelectInput,
    FormContainer,
    FormField,
    humanReadableConstant,
} from "@cobira/ui-library";
import { Button, HStack, Input, Text } from "@chakra-ui/react";
import { Role } from "../../api";
import RoleDescriptionPopover from "../../components/RoleDescriptionPopover/RoleDescriptionPopover";

export const InviteUserFormSchema = z.object({
    name: z.string().min(1, "Name is required"),
    email: z.string().min(1, "Email is required"),
    role: z.object(
        {
            id: z.string(),
            name: z.string(),
            permissions: z.array(z.string()),
        },
        {
            required_error: "Please select a role",
        },
    ),
});

export type InviteUserFormType = z.infer<typeof InviteUserFormSchema>;

export interface InviteUserFormProps {
    defaultValue?: InviteUserFormType;
    onSubmit: (form: InviteUserFormType) => void;
    availableRoles: Role[];
}

const InviteUserForm = ({ defaultValue, onSubmit, availableRoles }: InviteUserFormProps) => {
    const {
        formState: { errors },
        register,
        handleSubmit,
        control,
        watch,
    } = useForm<InviteUserFormType>({
        resolver: zodResolver(InviteUserFormSchema),
        defaultValues: defaultValue || undefined,
    });

    const selectedRole = watch("role");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Text fontSize={"xl"}>Fill the fields below to invite a User</Text>
            <FormContainer>
                <FormField label={"Name"} error={errors?.name}>
                    <Input {...register("name")} type={"text"} />
                </FormField>
                <FormField label={"Email"} error={errors?.email}>
                    <Input {...register("email")} type={"email"} />
                </FormField>
                <FormField label={"Role"} error={errors?.role}>
                    <HStack w={"100%"} gap={1}>
                        <ControlledAutoCompleteSingleSelectInput
                            control={{
                                control: control,
                                name: "role",
                            }}
                            input={{
                                autocompleteAbleMapping: value => value.name,
                                displayMapping: value => humanReadableConstant(value.name),
                                placeholder: "Select a role",
                                items: availableRoles,
                            }}
                        />
                        <RoleDescriptionPopover role={selectedRole} />
                    </HStack>
                </FormField>
            </FormContainer>
            <Button type={"submit"}>Invite</Button>
        </form>
    );
};

export default InviteUserForm;
