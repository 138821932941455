/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Origin description of an SMS. 
 * Mobile originating means the SMS was sent from the device.
 * While mobile terminated means the SMS was received by the device.
 * @export
 */
export const SmsDirection = {
    MobileOriginated: 'MOBILE_ORIGINATED',
    MobileTerminated: 'MOBILE_TERMINATED',
    Unknown: 'UNKNOWN'
} as const;
export type SmsDirection = typeof SmsDirection[keyof typeof SmsDirection];


export function SmsDirectionFromJSON(json: any): SmsDirection {
    return SmsDirectionFromJSONTyped(json, false);
}

export function SmsDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmsDirection {
    return json as SmsDirection;
}

export function SmsDirectionToJSON(value?: SmsDirection | null): any {
    return value as any;
}

