/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Additional classifier to further help identify what an action was meant to perform.
 * In some cases the action type is quite generic and depending on parameters it may perform different tasks, the classifier helps remove this generalization based on the requested operation.
 * @export
 */
export const ActionClassifier = {
    Unknown: 'UNKNOWN',
    UsagePackageAssignment: 'USAGE_PACKAGE_ASSIGNMENT',
    UsagePackageRevoke: 'USAGE_PACKAGE_REVOKE',
    SimCardActivate: 'SIM_CARD_ACTIVATE',
    SimCardSuspend: 'SIM_CARD_SUSPEND',
    SimCardReset: 'SIM_CARD_RESET',
    SetImeiLockState: 'SET_IMEI_LOCK_STATE',
    UpdateImeiLock: 'UPDATE_IMEI_LOCK',
    SendSms: 'SEND_SMS'
} as const;
export type ActionClassifier = typeof ActionClassifier[keyof typeof ActionClassifier];


export function ActionClassifierFromJSON(json: any): ActionClassifier {
    return ActionClassifierFromJSONTyped(json, false);
}

export function ActionClassifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionClassifier {
    return json as ActionClassifier;
}

export function ActionClassifierToJSON(value?: ActionClassifier | null): any {
    return value as any;
}

