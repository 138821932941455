import { Controller, ControllerProps, FieldValues, Path } from "react-hook-form";
import React, { PropsWithChildren, ReactNode } from "react";
import { Editable, EditableInput, EditablePreview, HStack } from "@chakra-ui/react";

export type ControlledEditableProps<
    TFieldValues extends FieldValues,
    TName extends Path<TFieldValues>,
> = PropsWithChildren &
    Omit<ControllerProps<TFieldValues, TName>, "render"> & {
        controls?: ReactNode;
        onSubmit: () => void;
    };

export const ControlledEditable = <TFieldValues extends FieldValues, TName extends Path<TFieldValues>>({
    controls,
    onSubmit,
    ...controlProps
}: ControlledEditableProps<TFieldValues, TName>) => {
    return (
        <Controller
            {...controlProps}
            render={({ field: { value, onChange } }) => (
                <Editable value={value?.length ? value : "N/A"} onChange={onChange} onSubmit={onSubmit}>
                    {props => (
                        <HStack>
                            <EditablePreview />
                            <EditableInput {...props} />
                            {controls}
                        </HStack>
                    )}
                </Editable>
            )}
        />
    );
};
