import { LabelProps, Labels, NotFound, Paper } from "@cobira/ui-library";
import React from "react";

import { type Label, SimCard } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";

interface SimCardLabelsPaperProps {
    simCard?: SimCard;
}

const mapLabelProps = (label: Label): LabelProps => ({ keyName: label.key, value: label.value ?? "", withIcon: false });

const SimCardLabelsPaper = ({ simCard }: SimCardLabelsPaperProps) => {
    const { simCardApi } = useApi();
    const { data: labels, isLoading: isLoading } = useQuery(
        ["simcards", simCard?.icc, "labels"],
        () => simCardApi.getSimCardLabels({ icc: simCard?.icc || "" }),
        { enabled: simCard?.icc !== undefined },
    );

    const mappedLabels: LabelProps[] | undefined = labels && labels.labels && labels.labels.map(mapLabelProps);
    const hasLabels = !isLoading && mappedLabels && mappedLabels.length > 0;

    return (
        <Paper header={"Labels"} withDivider>
            {hasLabels ? <Labels labels={mappedLabels} maxDisplay={6} /> : <NotFound resource={"Labels"} />}
        </Paper>
    );
};

export default SimCardLabelsPaper;
