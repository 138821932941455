import { PermissionUtils } from "@cobira/ui-library";

export const Permission = {
    accessToken: PermissionUtils.readWrite("access-token"),
    action: PermissionUtils.readWrite("action"),
    billingGroup: PermissionUtils.readWrite("billing-group"),
    integration: PermissionUtils.readWrite("integration"),
    networkCredential: PermissionUtils.readWrite("network-credential"),
    phoneNumber: PermissionUtils.readWrite("phone-number"),
    product: PermissionUtils.readWrite("product"),
    simCard: PermissionUtils.readWrite("sim-card"),
    usagePackage: PermissionUtils.readWrite("usage-package"),
    user: PermissionUtils.readWrite("user"),
    webhook: PermissionUtils.readWrite("webhook"),
    network: PermissionUtils.read("network"),
    role: PermissionUtils.read("role"),
};

export const WriteSimCardPermission = {
    values: [Permission.simCard.write],
};

export const WriteActionPermission = {
    values: [Permission.action.write],
};
