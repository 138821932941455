import React from "react";
import { CobiraTable, Page, PageTitle, PlaceholderText, usePageNavigation } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { BillingGroup } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { BillingGroupDataUsageCell } from "./BillingGroupDataUsageCell/BillingGroupDataUsageCell";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";

const billingGroupColumn = createColumnHelper<BillingGroup>();
const COLUMNS = [
    billingGroupColumn.accessor("name", {
        id: "name",
        header: "Name",
        cell: row => <Text>{row.getValue()}</Text>,
    }),
    billingGroupColumn.accessor("description", {
        id: "description",
        header: "Description",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    billingGroupColumn.accessor("id", {
        id: "currentMonthDataUsage",
        header: "Data last month",
        cell: row => <BillingGroupDataUsageCell billingGroupId={row.getValue()} period={"last-month"} />,
    }),
    billingGroupColumn.accessor("id", {
        id: "monthlyDataUsage",
        header: "Data current month",
        cell: row => <BillingGroupDataUsageCell billingGroupId={row.getValue()} period={"current-month"} />,
    }),
    billingGroupColumn.accessor("id", {
        id: "thisYearDataUsage",
        header: "Data current year",
        cell: row => <BillingGroupDataUsageCell billingGroupId={row.getValue()} period={"one-year"} />,
    }),
];

const BillingGroupsListPage = () => {
    const { billingGroupApi } = useApi();
    const { navigate } = usePageNavigation<BillingGroup>({ route: value => `/billing-groups/${value.id}` });
    const { data: billingGroups, isLoading } = useQuery(["billingGroups"], () => {
        return billingGroupApi.getBillingGroups();
    });

    return (
        <Page
            title={<PageTitle title="Billing Groups" />}
            permissions={{ values: [Permission.billingGroup.read], deniedComponent: <RestrictedPage /> }}
        >
            <CobiraTable
                sizing={"fit-page"}
                columns={COLUMNS}
                data={billingGroups || []}
                withPagination
                withRowClick={{
                    onRowClicked: navigate,
                    enableHoverStyle: true,
                }}
                isLoading={isLoading}
            />
        </Page>
    );
};

export default BillingGroupsListPage;
