/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimCardDataUsageDataUsageInner } from './SimCardDataUsageDataUsageInner';
import {
    SimCardDataUsageDataUsageInnerFromJSON,
    SimCardDataUsageDataUsageInnerFromJSONTyped,
    SimCardDataUsageDataUsageInnerToJSON,
} from './SimCardDataUsageDataUsageInner';

/**
 * Representation of a SIM cards data usage in a given period.
 * @export
 * @interface SimCardDataUsage
 */
export interface SimCardDataUsage {
    /**
     * 
     * @type {string}
     * @memberof SimCardDataUsage
     */
    icc: string;
    /**
     * 
     * @type {number}
     * @memberof SimCardDataUsage
     */
    totalBytes: number;
    /**
     * 
     * @type {number}
     * @memberof SimCardDataUsage
     */
    totalBillableBytes: number;
    /**
     * 
     * @type {Array<SimCardDataUsageDataUsageInner>}
     * @memberof SimCardDataUsage
     */
    dataUsage?: Array<SimCardDataUsageDataUsageInner>;
}

/**
 * Check if a given object implements the SimCardDataUsage interface.
 */
export function instanceOfSimCardDataUsage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "icc" in value;
    isInstance = isInstance && "totalBytes" in value;
    isInstance = isInstance && "totalBillableBytes" in value;

    return isInstance;
}

export function SimCardDataUsageFromJSON(json: any): SimCardDataUsage {
    return SimCardDataUsageFromJSONTyped(json, false);
}

export function SimCardDataUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardDataUsage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'icc': json['icc'],
        'totalBytes': json['totalBytes'],
        'totalBillableBytes': json['totalBillableBytes'],
        'dataUsage': !exists(json, 'dataUsage') ? undefined : ((json['dataUsage'] as Array<any>).map(SimCardDataUsageDataUsageInnerFromJSON)),
    };
}

export function SimCardDataUsageToJSON(value?: SimCardDataUsage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'icc': value.icc,
        'totalBytes': value.totalBytes,
        'totalBillableBytes': value.totalBillableBytes,
        'dataUsage': value.dataUsage === undefined ? undefined : ((value.dataUsage as Array<any>).map(SimCardDataUsageDataUsageInnerToJSON)),
    };
}

