import React, { useCallback } from "react";
import { BasicModal, HasPermission, useNotifications } from "@cobira/ui-library";
import WebhookForm, { WebhookFormType } from "../../../forms/WebhookForm/WebhookForm";
import { useApi } from "../../../hooks/useApi";
import { useMutation } from "@tanstack/react-query";
import { WebhookApiCreateWebhookSubscriptionRequest, WebhookSubscription } from "../../../api";
import { Permission } from "../../../utils/Permissions";

export interface CreateWebhookModalProps {
    isOpen: boolean;
    onClose: (created: boolean) => void;
}

const CreateWebhookModal = ({ isOpen, onClose }: CreateWebhookModalProps) => {
    const { webhookApi } = useApi();
    const notifications = useNotifications();

    const createWebhook = useMutation<WebhookSubscription, Error, WebhookApiCreateWebhookSubscriptionRequest>(
        variables => webhookApi.createWebhookSubscription(variables),
        {
            onSuccess: webhook => {
                notifications.success(`Created ${webhook.name}`);
                onClose(true);
            },
            onError: error => notifications.error(error?.message),
        },
    );

    const handleWebhookFormSubmit = useCallback(
        (form: WebhookFormType) => {
            createWebhook.mutate({
                createWebhookSubscription: {
                    name: form.name,
                    description: form.description,
                    target: form.target,
                    subscribedTypes: form.types,
                },
            });
        },
        [createWebhook],
    );

    return (
        <HasPermission values={[Permission.webhook.write]} type={"allOf"}>
            <BasicModal isOpen={isOpen} onClose={() => onClose(false)} title={"Create webhook subscription"}>
                <WebhookForm onSubmit={handleWebhookFormSubmit} />
            </BasicModal>
        </HasPermission>
    );
};

export default CreateWebhookModal;
