import React from "react";
import { BillingGroup } from "../../../api";
import { BillingGroupSmsConsumptionChart } from "../../../components/Charts/BillingGroupSmsConsumptionChart/BillingGroupSmsConsumptionChart";
import { Loading } from "@cobira/ui-library";
import { Box } from "@chakra-ui/react";

export interface BillingGroupSmsUsagePaperProps {
    billingGroup?: BillingGroup;
}

const BillingGroupSmsUsagePaper = ({ billingGroup }: BillingGroupSmsUsagePaperProps) => {
    if (!billingGroup) {
        return <Loading />;
    }

    return (
        <Box w={"100%"} h={"600px"}>
            <BillingGroupSmsConsumptionChart billingGroupId={billingGroup?.id} />
        </Box>
    );
};

export default BillingGroupSmsUsagePaper;
