import React from "react";
import { SearchSelectInput, SearchSingleInputProps, SearchStateConfig } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { BillingGroup } from "../../../api";
import { useApi } from "../../../hooks/useApi";

export interface SearchSelectBillingGroupInputProps<
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
> extends Omit<SearchSingleInputProps<BillingGroup, K, TSearchStateConfig>, "label"> {}

export const SearchSelectBillingGroupInput = <
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
>({
    registration,
    placeholder = "Select Billing Group",
}: SearchSelectBillingGroupInputProps<K, TSearchStateConfig>) => {
    const { billingGroupApi } = useApi();

    const { data: items } = useQuery(["billinggroups"], () => billingGroupApi.getBillingGroups());
    return (
        <SearchSelectInput
            registration={registration}
            items={items || []}
            placeholder={placeholder}
            autocompleteAbleMapping={billingGroup => billingGroup.name}
            displayMapping={billingGroup => billingGroup.name}
        />
    );
};
