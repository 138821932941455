import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { Integration, WebhookApiDeleteWebhookSubscriptionRequest, WebhookSubscription } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";

export interface ConfirmDeleteWebhookModalProps {
    onClose: () => void;
    webhook?: WebhookSubscription;
    integration?: Integration;
}

const ConfirmDeleteWebhookModal = ({ onClose, webhook, integration }: ConfirmDeleteWebhookModalProps) => {
    const { webhookApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const deleteWebhook = useMutation<void, Error, WebhookApiDeleteWebhookSubscriptionRequest>(variables =>
        webhookApi.deleteWebhookSubscription(variables),
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && webhook?.id) {
                deleteWebhook.mutate(
                    { webhookSubscriptionId: webhook.id },
                    {
                        onSuccess: () => {
                            notifications.success("Webhook deleted");
                            queryClient.invalidateQueries(["integrations", integration?.id, "webhooks"]);
                        },
                        onError: error => notifications.error(error?.message),
                    },
                );
            }
            onClose();
        },
        [onClose, deleteWebhook, integration, queryClient, webhook, notifications],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!webhook}
            title={"Delete webhook subscription"}
            description={
                <Container>
                    <Text>You are about to delete the webhook subscription</Text>
                    <Text as={"b"}>{webhook?.name}</Text>
                </Container>
            }
        />
    );
};

export default ConfirmDeleteWebhookModal;
