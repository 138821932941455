import React from "react";
import { Role } from "../../api";
import {
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
} from "@chakra-ui/react";
import { InfoIcon, PlaceholderText } from "@cobira/ui-library";

export interface RoleNameWithPermissionTooltipProps {
    role?: Role;
}

const RoleDescriptionPopover = ({ role }: RoleNameWithPermissionTooltipProps) => {
    return (
        <Popover>
            <PopoverTrigger>
                <IconButton
                    aria-label={"Permission Info"}
                    icon={<InfoIcon />}
                    isRound
                    fontSize={"24"}
                    variant={"icon"}
                    isDisabled={!role}
                />
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Role description</PopoverHeader>
                <PopoverBody>
                    <PlaceholderText text={role?.description} placeholderText={"No role description provided"} />
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default RoleDescriptionPopover;
