import React, { useMemo, useState } from "react";
import { Integration, WebhookSubscription } from "../../../api";
import { IconButton, TabPanel } from "@chakra-ui/react";
import { CobiraTable, DeleteIcon, PlaceholderText, usePagination } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import ConfirmDeleteWebhookModal from "../../../components/Modal/ConfirmDeleteWebhookModal/ConfirmDeleteWebhookModal";
import WebhookDetailModal from "../../../components/Modal/WebhookDetailModal/WebhookDetailModal";
import { createColumnHelper } from "@tanstack/react-table";

export interface WebhookPanelsProps {
    integration?: Integration;
}

type OnDeleteFunction = (webhook: WebhookSubscription) => void;

const webhookColumn = createColumnHelper<WebhookSubscription>();
const COLUMNS = (onDelete: OnDeleteFunction) => [
    webhookColumn.accessor("name", {
        id: "name",
        header: "Name",
        cell: row => row.getValue(),
    }),
    webhookColumn.accessor("description", {
        id: "description",
        header: "Description",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    webhookColumn.accessor("target", {
        id: "target",
        header: "Target",
        cell: row => row.getValue(),
    }),
    webhookColumn.accessor("subscribedTypes", {
        id: "typeCount",
        header: "Subscriptions",
        cell: row => row.getValue().length,
    }),
    webhookColumn.accessor("deliveryTimeout", {
        id: "deliveryTimeout",
        header: "Timeout",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    webhookColumn.display({
        id: "actions",
        meta: {
            isButton: true,
        },
        cell: content => (
            <IconButton
                aria-label={"delete"}
                icon={<DeleteIcon />}
                colorScheme={"red"}
                variant={"icon"}
                isRound
                onClick={() => onDelete(content.row.original)}
            />
        ),
    }),
];

const WebhooksPanel = ({ integration }: WebhookPanelsProps) => {
    const { webhookApi } = useApi();
    const [selectedWebhook, setSelectedWebhook] = useState<WebhookSubscription | undefined>(undefined);
    const [webhookToDelete, setWebhookToDelete] = useState<WebhookSubscription | undefined>(undefined);
    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 10,
    });
    const { data: webhooks, isLoading } = useQuery(["integrations", integration?.id, "webhooks"], () =>
        webhookApi.getWebhookSubscriptions({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
        }),
    );

    const columns = useMemo(() => COLUMNS(setWebhookToDelete), [setWebhookToDelete]);

    return (
        <TabPanel>
            <CobiraTable
                columns={columns}
                data={webhooks?.content || []}
                sizing={"fit-page"}
                isLoading={isLoading}
                withPagination={{
                    pageIndex: pageState.pageIndex,
                    pageSize: pageState.pageSize,
                    onPaginationChange: setPageState,
                    totalRowCount: webhooks?.pageProperties?.totalElements || 0,
                    totalPageCount: webhooks?.pageProperties?.totalPages || 0,
                }}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: setSelectedWebhook,
                }}
            />
            <WebhookDetailModal onClose={() => setSelectedWebhook(undefined)} webhook={selectedWebhook} />
            <ConfirmDeleteWebhookModal
                onClose={() => setWebhookToDelete(undefined)}
                webhook={webhookToDelete}
                integration={integration}
            />
        </TabPanel>
    );
};

export default WebhooksPanel;
