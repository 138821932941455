/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingGroupSmsUsageSmsUsageInner } from './BillingGroupSmsUsageSmsUsageInner';
import {
    BillingGroupSmsUsageSmsUsageInnerFromJSON,
    BillingGroupSmsUsageSmsUsageInnerFromJSONTyped,
    BillingGroupSmsUsageSmsUsageInnerToJSON,
} from './BillingGroupSmsUsageSmsUsageInner';

/**
 * 
 * @export
 * @interface BillingGroupSmsUsage
 */
export interface BillingGroupSmsUsage {
    /**
     * 
     * @type {string}
     * @memberof BillingGroupSmsUsage
     */
    billingGroupId: string;
    /**
     * 
     * @type {number}
     * @memberof BillingGroupSmsUsage
     */
    totalMobileOriginated: number;
    /**
     * 
     * @type {number}
     * @memberof BillingGroupSmsUsage
     */
    totalMobileTerminated: number;
    /**
     * 
     * @type {Array<BillingGroupSmsUsageSmsUsageInner>}
     * @memberof BillingGroupSmsUsage
     */
    smsUsage: Array<BillingGroupSmsUsageSmsUsageInner>;
}

/**
 * Check if a given object implements the BillingGroupSmsUsage interface.
 */
export function instanceOfBillingGroupSmsUsage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "billingGroupId" in value;
    isInstance = isInstance && "totalMobileOriginated" in value;
    isInstance = isInstance && "totalMobileTerminated" in value;
    isInstance = isInstance && "smsUsage" in value;

    return isInstance;
}

export function BillingGroupSmsUsageFromJSON(json: any): BillingGroupSmsUsage {
    return BillingGroupSmsUsageFromJSONTyped(json, false);
}

export function BillingGroupSmsUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingGroupSmsUsage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingGroupId': json['billingGroupId'],
        'totalMobileOriginated': json['totalMobileOriginated'],
        'totalMobileTerminated': json['totalMobileTerminated'],
        'smsUsage': ((json['smsUsage'] as Array<any>).map(BillingGroupSmsUsageSmsUsageInnerFromJSON)),
    };
}

export function BillingGroupSmsUsageToJSON(value?: BillingGroupSmsUsage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billingGroupId': value.billingGroupId,
        'totalMobileOriginated': value.totalMobileOriginated,
        'totalMobileTerminated': value.totalMobileTerminated,
        'smsUsage': ((value.smsUsage as Array<any>).map(BillingGroupSmsUsageSmsUsageInnerToJSON)),
    };
}

