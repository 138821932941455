/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImeiLockState } from './ImeiLockState';
import {
    ImeiLockStateFromJSON,
    ImeiLockStateFromJSONTyped,
    ImeiLockStateToJSON,
} from './ImeiLockState';
import type { ImeiLockViolationBehaviour } from './ImeiLockViolationBehaviour';
import {
    ImeiLockViolationBehaviourFromJSON,
    ImeiLockViolationBehaviourFromJSONTyped,
    ImeiLockViolationBehaviourToJSON,
} from './ImeiLockViolationBehaviour';

/**
 * 
 * @export
 * @interface NetworkCredentialSettings
 */
export interface NetworkCredentialSettings {
    /**
     * 
     * @type {ImeiLockState}
     * @memberof NetworkCredentialSettings
     */
    imeiLockState?: ImeiLockState;
    /**
     * 
     * @type {ImeiLockViolationBehaviour}
     * @memberof NetworkCredentialSettings
     */
    imeiLockViolationBehaviour?: ImeiLockViolationBehaviour;
}

/**
 * Check if a given object implements the NetworkCredentialSettings interface.
 */
export function instanceOfNetworkCredentialSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NetworkCredentialSettingsFromJSON(json: any): NetworkCredentialSettings {
    return NetworkCredentialSettingsFromJSONTyped(json, false);
}

export function NetworkCredentialSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkCredentialSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imeiLockState': !exists(json, 'imeiLockState') ? undefined : ImeiLockStateFromJSON(json['imeiLockState']),
        'imeiLockViolationBehaviour': !exists(json, 'imeiLockViolationBehaviour') ? undefined : ImeiLockViolationBehaviourFromJSON(json['imeiLockViolationBehaviour']),
    };
}

export function NetworkCredentialSettingsToJSON(value?: NetworkCredentialSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imeiLockState': ImeiLockStateToJSON(value.imeiLockState),
        'imeiLockViolationBehaviour': ImeiLockViolationBehaviourToJSON(value.imeiLockViolationBehaviour),
    };
}

