import React, { FC } from "react";
import {
    CobiraBarChartPaper,
    MetricLabelFormatters,
    MetricsProvider,
    MetricTooltipFormatters,
    newRequest,
    TickFormatters,
} from "@cobira/ui-library";
import { useMetricsResolver } from "../../../hooks/useMetricsResolver";
import { subMonths } from "date-fns";

interface CustomerDataConsumptionChartProps {
    billingGroupId: string;
}

export const BillingGroupDataConsumptionChart: FC<CustomerDataConsumptionChartProps> = ({ billingGroupId }) => {
    const metricResolver = useMetricsResolver();
    const thisMonth = new Date();
    const twelveMonthsAgo = subMonths(thisMonth, 12);

    const requests = {
        totalData: newRequest({
            bucket: "MONTHLY",
            dataPoint: "DATA_USAGE",
            group: "BILLING_GROUP",
            groupId: billingGroupId,
            fromDateTime: twelveMonthsAgo,
            toDateTime: thisMonth,
        }),
    };

    return (
        <MetricsProvider requests={requests} resolver={metricResolver}>
            {data => (
                <CobiraBarChartPaper
                    title={"Data consumption"}
                    chartContext={"Last 12 months"}
                    bars={[{ dataSet: data => data.totalData.value, dataSetName: "Data used" }]}
                    data={data}
                    chartConfig={{
                        xAxisValueLabel: data => MetricLabelFormatters.yearAndMonth(data.totalData),
                        yAxisTickFormatter: TickFormatters.dataTickFormatter,
                        tooltipFormatter: MetricTooltipFormatters.dataTooltip,
                    }}
                />
            )}
        </MetricsProvider>
    );
};
