/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DataPoint = {
    DataUsage: 'DATA_USAGE',
    SmsUsage: 'SMS_USAGE',
    ActiveSim: 'ACTIVE_SIM',
    AssignedSim: 'ASSIGNED_SIM',
    Unknown: 'UNKNOWN'
} as const;
export type DataPoint = typeof DataPoint[keyof typeof DataPoint];


export function DataPointFromJSON(json: any): DataPoint {
    return DataPointFromJSONTyped(json, false);
}

export function DataPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataPoint {
    return json as DataPoint;
}

export function DataPointToJSON(value?: DataPoint | null): any {
    return value as any;
}

