import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { Paper, useCobiraEnvironment } from "@cobira/ui-library";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import "./swagger-ui-override.css";

const ApiDocumentationPanel = () => {
    const [, { apiUrl }] = useCobiraEnvironment();
    return (
        <TabPanel>
            <Paper header={""} withDivider={false}>
                <SwaggerUI
                    url={`${apiUrl}/openapi.yaml`}
                    showExtensions
                    showCommonExtensions
                    tryItOutEnabled={false}
                    withCredentials={false}
                    requestSnippetsEnabled={false}
                    defaultModelRendering={"example"}
                    defaultModelExpandDepth={0}
                    defaultModelsExpandDepth={0}
                    docExpansion={"none"}
                    plugins={[
                        () => ({
                            components: {
                                info: () => null,
                                AuthorizeBtnContainer: () => null,
                                authorizeOperationBtn: () => null,
                                TryItOutButton: () => null,
                            },
                        }),
                    ]}
                />
            </Paper>
        </TabPanel>
    );
};

export default ApiDocumentationPanel;
