/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ActionItemState = {
    Pending: 'PENDING',
    Success: 'SUCCESS',
    Skipped: 'SKIPPED',
    Failed: 'FAILED'
} as const;
export type ActionItemState = typeof ActionItemState[keyof typeof ActionItemState];


export function ActionItemStateFromJSON(json: any): ActionItemState {
    return ActionItemStateFromJSONTyped(json, false);
}

export function ActionItemStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionItemState {
    return json as ActionItemState;
}

export function ActionItemStateToJSON(value?: ActionItemState | null): any {
    return value as any;
}

