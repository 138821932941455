import { ImeiLockViolationBehaviour } from "../api";

export const ImeiLockViolationBehaviourLabels = {
    [ImeiLockViolationBehaviour.Ignore]: "Ignore IMEI violations",
    [ImeiLockViolationBehaviour.SuspendSimCard]: "Suspend SIM Card on violation",

    getLabel: (value?: ImeiLockViolationBehaviour) => {
        return value ? ImeiLockViolationBehaviourLabels[value] : undefined;
    },
};
