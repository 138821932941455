import React from "react";
import { useApi } from "../../../../hooks/useApi";
import { Text } from "@chakra-ui/react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";

export interface BillingGroupCellProps {
    billingGroupId?: string;
}

export const BillingGroupCell = ({ billingGroupId }: BillingGroupCellProps) => {
    const { billingGroupApi } = useApi();
    if (!billingGroupId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () => billingGroupApi.getBillingGroup({ billingGroupId: billingGroupId }),
                queryKey: ["billing-groups", billingGroupId],
            }}
            render={result => <Text>{result?.data?.name}</Text>}
        />
    );
};
