import { NetworkState } from "../../api";
import { IndicatorColors, IndicatorLabel } from "@cobira/ui-library";
import React from "react";

export interface NetworkStateIndicatorProps {
    state?: NetworkState;
    withLabel?: boolean;
}

const NetworkStateIndicator = ({ state, withLabel = false }: NetworkStateIndicatorProps) => {
    let color: IndicatorColors = "gray";
    let label = "Unknown";
    if (state) {
        switch (state) {
            case "ACTIVE":
                color = "green";
                label = "Active";
                break;
            case "IDLE":
                color = "gray";
                label = "Never used";
                break;
            case "TERMINATED":
                color = "red";
                label = "Terminated";
                break;
            case "SUSPENDED":
                color = "yellow";
                label = "Suspended";
                break;
        }
    }

    return <IndicatorLabel text={withLabel ? label : ""} color={color} />;
};

export default NetworkStateIndicator;
