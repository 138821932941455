import {
    ActionIcon,
    BillingGroupIcon,
    DashboardIcon,
    IntegrationIcon,
    SidebarItem,
    SimCardIcon,
    UsagePackageTypeIcon,
    UserIcon,
} from "@cobira/ui-library";
import { Permission } from "../../utils/Permissions";

const SidebarItems: SidebarItem[] = [
    {
        title: "Dashboard",
        path: "/dashboards/account",
        icon: DashboardIcon,
    },
    {
        title: "SIM Inventory",
        path: "/simcards",
        icon: SimCardIcon,
        permissions: {
            values: [Permission.simCard.read],
        },
    },
    {
        title: "Actions",
        path: "/actions",
        icon: ActionIcon,
        permissions: {
            values: [Permission.action.read],
        },
    },
    {
        title: "Billing Groups",
        path: "/billing-groups",
        icon: BillingGroupIcon,
        permissions: {
            values: [Permission.billingGroup.read],
        },
    },
    {
        title: "Integrations",
        path: "/integration",
        icon: IntegrationIcon,
        permissions: {
            values: [Permission.integration.read],
        },
    },
    {
        title: "Usage Package Types",
        path: "/usage-package-types",
        icon: UsagePackageTypeIcon,
        permissions: {
            values: [Permission.usagePackage.read],
        },
    },
    {
        title: "Users",
        path: "/users",
        icon: UserIcon,
        permissions: {
            values: [Permission.user.read],
        },
    },
];

export default SidebarItems;
