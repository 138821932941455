import { SimCardActionRequestFilter, SimCardApiGetSimCardsRequest } from "../api";

export const mapFromActionFilterToSearchFilter = (
    pageNumber: number,
    pageSize: number,
    filter?: SimCardActionRequestFilter,
): SimCardApiGetSimCardsRequest => ({
    pageNumber: pageNumber,
    pageSize: pageSize,
    simConfigurationId: filter?.simConfigurationId || undefined,
    labels: filter?.labels || undefined,
    networkState: filter?.networkState || undefined,
    iccUpperBound: filter?.iccUpperBound || undefined,
    iccLowerBound: filter?.iccLowerBound || undefined,
    billingGroupId: filter?.billingGroupId || undefined,
    esimId: filter?.esimId || undefined,
    search: filter?.search || undefined,
});

export const mapFromSearchFilterToActionFilter = (
    filter?: SimCardApiGetSimCardsRequest,
): SimCardActionRequestFilter => ({
    simConfigurationId: filter?.simConfigurationId || undefined,
    labels: filter?.labels || undefined,
    networkState: filter?.networkState || undefined,
    iccUpperBound: filter?.iccUpperBound || undefined,
    iccLowerBound: filter?.iccLowerBound || undefined,
    billingGroupId: filter?.billingGroupId || undefined,
    esimId: filter?.esimId || undefined,
    search: filter?.search || undefined,
});
