import React from "react";
import { Page, PageBackButton, PageTitle, Paper, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { Grid, VStack } from "@chakra-ui/react";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";
import BillingGroupSimCardCountInfoCards from "./BillingGroupSimCardCountInfoCards";
import BillingGroupDataUsagePaper from "./BillingGroupDataUsagePaper";
import BillingGroupSimCountPaper from "./BillingGroupSimCountPaper";
import BillingGroupSmsUsagePaper from "./BillingGroupSmsUsagePaper";

const BillingGroupDetailPage = () => {
    const { billingGroupApi } = useApi();
    const { billingGroupId } = useParams();

    const { data: billingGroup, isLoading } = useQuery(
        ["billing-groups", billingGroupId],
        () => billingGroupApi.getBillingGroup({ billingGroupId: billingGroupId || "" }),
        {
            enabled: billingGroupId != undefined,
        },
    );

    return (
        <Page
            title={<PageTitle title={billingGroup?.name || "N/A"} />}
            navigation={<PageBackButton text={"Back to Billing groups"} path={"/billing-groups"} />}
            permissions={{ values: [Permission.billingGroup.read], deniedComponent: <RestrictedPage /> }}
        >
            <VStack w="100%" align="stretch" gap={6}>
                <BillingGroupSimCardCountInfoCards billingGroup={billingGroup} isLoading={isLoading} />
                <Paper header={"Details"}>
                    <Grid templateColumns="auto auto auto" gap="4">
                        <TextColumn heading="Name" grid isLoading={isLoading}>
                            {billingGroup?.name}
                        </TextColumn>
                        <TextColumn heading="Description" grid isLoading={isLoading}>
                            <PlaceholderText text={billingGroup?.description} />
                        </TextColumn>
                    </Grid>
                </Paper>
                <BillingGroupSimCountPaper billingGroup={billingGroup} />
                <BillingGroupDataUsagePaper billingGroup={billingGroup} />
                <BillingGroupSmsUsagePaper billingGroup={billingGroup} />
            </VStack>
        </Page>
    );
};

export default BillingGroupDetailPage;
