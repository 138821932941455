import React from "react";
import { DataText, LoadingSkeleton, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../../hooks/useApi";
import { subMonths } from "date-fns";

export interface BillingGroupDataUsageCell {
    billingGroupId: string;
    period: "one-year" | "current-month" | "last-month";
}

export const BillingGroupDataUsageCell = ({ billingGroupId, period }: BillingGroupDataUsageCell) => {
    const { metricsApi } = useApi();

    if (period === "one-year") {
        const thisMonth = new Date();
        const twelveMonthsAgo = subMonths(thisMonth, 12);
        return (
            <QueryCell
                loadingComponent={<LoadingSkeleton />}
                query={{
                    queryFn: () =>
                        metricsApi.getMetrics({
                            groupId: billingGroupId,
                            group: "BILLING_GROUP",
                            dataPoint: "DATA_USAGE",
                            fromDateTime: twelveMonthsAgo,
                            toDateTime: thisMonth,
                        }),
                    queryKey: ["metrics", billingGroupId, "BILLING_GROUP", "DATA_USAGE", twelveMonthsAgo, thisMonth],
                }}
                render={result => (
                    <DataText
                        bytes={result?.data
                            ?.map(metric => metric.value)
                            .reduce((result, currentValue) => result + currentValue, 0)}
                        decimals={2}
                    />
                )}
            />
        );
    } else if (period === "current-month") {
        const thisMonth = new Date();
        return (
            <QueryCell
                loadingComponent={<LoadingSkeleton />}
                query={{
                    queryFn: () =>
                        metricsApi.getMetrics({
                            groupId: billingGroupId,
                            group: "BILLING_GROUP",
                            dataPoint: "DATA_USAGE",
                            fromDateTime: thisMonth,
                            toDateTime: thisMonth,
                        }),
                    queryKey: ["metrics", billingGroupId, "BILLING_GROUP", "DATA_USAGE", thisMonth, thisMonth],
                }}
                render={result => (
                    <DataText
                        bytes={result?.data
                            ?.map(metric => metric.value)
                            .reduce((result, currentValue) => result + currentValue, 0)}
                        decimals={2}
                    />
                )}
            />
        );
    } else {
        const thisMonth = new Date();
        const lastMonth = subMonths(thisMonth, 1);
        return (
            <QueryCell
                loadingComponent={<LoadingSkeleton />}
                query={{
                    queryFn: () =>
                        metricsApi.getMetrics({
                            groupId: billingGroupId,
                            group: "BILLING_GROUP",
                            dataPoint: "DATA_USAGE",
                            fromDateTime: lastMonth,
                            toDateTime: lastMonth,
                        }),
                    queryKey: ["metrics", billingGroupId, "BILLING_GROUP", "DATA_USAGE", lastMonth, thisMonth],
                }}
                render={result => (
                    <DataText
                        bytes={result?.data
                            ?.map(metric => metric.value)
                            .reduce((result, currentValue) => result + currentValue, 0)}
                        decimals={2}
                    />
                )}
            />
        );
    }
};
