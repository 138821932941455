import { DataPoint, MetricGroup, MetricResponse, TimeBucket } from "../api";
import { Metric, MetricDimensions } from "@cobira/ui-library";

export const mapMetricResponse = (metricResponse: MetricResponse): Metric => ({
    bucket: metricResponse.bucket ?? TimeBucket.Unknown,
    dataPoint: metricResponse.dataPoint ?? DataPoint.Unknown,
    group: metricResponse.group ?? MetricGroup.Unknown,
    groupId: metricResponse.groupId ?? "",
    value: metricResponse.value || 0,
    forDateTime: metricResponse.forDateTime || new Date(),
    dimensions: metricResponse.dimensions?.map(dimension => ({
        dimension: (dimension.dimension ?? "UNKNOWN") as MetricDimensions,
        id: dimension.id,
    })),
});
